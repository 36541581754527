<script>
  import { RouterLink } from '../router'
  import { Button, Collapse, Icon } from 'svelma-fixed'
  import { devOptions } from '../stores/devOptions'
  import { activeWallet } from '../stores/onboard'
  import safeStringify from 'fast-safe-stringify'

  function loadDebugger () {
    const script = document.createElement('script')
    script.src = 'https://debugjs.net/debug.js'
    script.addEventListener('load', () => {
      window.dbg.init({
        visible: true,
        zoom: 1.3,
        position: 'nw',
        width: Math.round(Math.min(document.body.innerWidth * 0.98 - 55, 600)),
        adjX: 5,
        adjY: 5
      })
      window.dbg.setWindowSize('expand')
    })
    document.body.appendChild(script)
  }
</script>

<svelte:head>
	<title>Doc Brown's Garage - WEHODL</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <div class="is-pulled-right">
      <img style:width={'300px'} style:max-width={'33vw'} src="https://i.imgur.com/buBxOGs.png" alt="Doc Brown wearing goggles" />
    </div>
    <h4 class="title is-4">
      Doc Brown's Garage
    </h4>

    <p>
      Welcome to Doc Brown's Garage, the place where we test new features and debug issues. Please note that this page is not meant for public consumption and may contain broken features and other weird stuff. Also, changes of flags or settings on this page may require a page reload to take effect!
    </p>

    <Button on:click={() => location.reload()}>Reload Page</Button>

    <hr style:clear="both" />

    <h5 class="title is-5">Feature Flags</h5>

    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={$devOptions.featureFlags.thorchainSupport}>
        Enable THORChain support
      </label>
    </div>

    <!-- <div>There are no feature flags at the moment.</div> -->

    <hr />

    <h5 class="title is-5">Debug Options</h5>

    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={$devOptions.debugOptions.comparisonMultiApy}>
        Show different APY calculations at once
      </label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={$devOptions.debugOptions.showOnboardAccountCenter}>
        Show Blocknative account center widget
      </label>
    </div>

    <div>
      <Collapse open={false}>
        <a href={undefined} slot="trigger">Onboard wallet JSON</a>
        <pre>{safeStringify($activeWallet, (key, value) => key === 'provider' ? '<provider>' : value, 2)}</pre>
      </Collapse>
    </div>

    <div>
      <Button on:click={loadDebugger}>Load Debugger</Button>
    </div>

    <hr />

    <h5 class="title is-5">Test Pages</h5>

    <ul>
      <li>
        <RouterLink to={{ name: 'txTest' }}>TX Test Page</RouterLink>
      </li>
      <li>
        <RouterLink to={{ name: 'componentTest' }}>Component Test Page</RouterLink>
      </li>
      <li>
        <RouterLink to={{ name: 'txManagerTest' }}>TX Manager Test Page</RouterLink>
      </li>
    </ul>

    <hr />

    <RouterLink to={{ name: 'HOME' }}><Icon icon="home" /> Navigate to home</RouterLink>

  </div>
</div>
