<script>
  import { fly } from 'svelte/transition'

  export let open = false
  export let forceClose = false
  export let fullWidth = false

  $: if (forceClose && open) open = false

  let element

  function toggle () {
    open = forceClose ? false : !open
  }

  function bodyClick (event) {
    if (event.target.closest('.dropdown') !== element) open = false
  }

  function ddClick (event) {
    // Caller can use stopPropagation to prevent this
    if (event.target.closest('.dropdown-item') && !event.target.closest('.keep-dropdown-open')) open = false
  }
</script>

<style lang="scss">
  .fullWidth {
    width: 100%;
  }
</style>

<svelte:body on:click={bodyClick} />

<div class="dropdown is-active {$$props.class}" bind:this={element} class:fullWidth>
  <slot name="customTrigger" {open} {toggle} />

  {#if $$slots.trigger}
    <div class="dropdown-trigger" on:click={toggle} class:fullWidth>
      <slot name="trigger" {open} {toggle} />
    </div>
  {/if}

  {#if open}
    <div class="dropdown-menu" role="menu" transition:fly|local={{ y: -10, duration: 150 }}>
      <div class="dropdown-content" on:click={ddClick}>
        <slot {open} {toggle} />
      </div>
    </div>
  {/if}
</div>
