<script>
  import { Switch } from 'svelma-fixed'
  import Tooltip from './Tooltip.svelte'
  import { getDispSymbol } from '../stores/markets'
  import { createEventDispatcher } from 'svelte'
  import { formatCurrency } from '../lib/utils'
  import { quoteCoin } from '../stores/quoteCoin'
  import { calculatorState } from '../stores/calculatorState'

  const dispatch = createEventDispatcher()

  export let augmentedAsset
  export let service
  export let type
  export let quoteCoinEthPrice
  export let showAmounts = true
  export let showRemove = false
  export let showEnable = false
  export let noFunds = false
  export let units = null

  $: disp = getDispSymbol(augmentedAsset.symbol, service, true)

  $: quoteCoinEthPrice = $calculatorState.quoteCoinPrices[$quoteCoin.symbol] ?? $quoteCoin.ethPrice
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .card-content {
    position: relative;
    padding: 0.5rem;
  }

  a.asset-link {
    color: $text;

    &:hover .asset {
      box-shadow: 0 0 0 5px $border;

      .show-on-hover {
        opacity: 1;
      }
    }
  }

  .asset {
    border-radius: $radius-small;
    background-color: $field-bg;
    margin: 15px 0;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;

    .card-content {
      line-height: 1.35;
    }

    // To support text ellipsis
    .media {
      max-width: 100%;

      .media-content {
        min-width: 0;

        .level {
          max-width: 100%;
          gap: 3%;

          .level-left {
            max-width: 75%;

            .asset-symbol span {
              min-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .level-right {
            overflow: hidden;
            min-width: 0;
            flex: 1;

            .asset-units, .asset-quote-value {
              min-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .level-item {
            max-width: 100%;
          }
        }
      }
    }

    .image {
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    .level {
      align-items: flex-start;
    }

    .level-left {
      text-align: left;
    }
    .level-right {
      text-align: right;
    }

    .level-item {
      display: block;
    }

    hr {
      background-color: $field-bg-hr;
      margin: 5px 0;
      height: 1px;
    }

    &.disabled, &.noFunds {
      color: $grey;
    }

    &.noFunds {
      cursor: not-allowed;
    }

    &.unsupported {
      color: $danger;

      .asset-quote-value {
        color: $danger;
      }

      .asset-symbol span {
        text-decoration: line-through;
      }
    }

    .asset-symbol, .asset-units {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .asset-name {
      font-size: 0.67rem;
      color: $grey;
    }

    .asset-price {
      font-size: 0.8rem;
      color: $grey;
    }

    .asset-quote-value {
      color: $grey;
      font-size: 0.9rem;
    }

    .asset-enabled {
      position: relative;
      top: 4px;

      span {
        position: relative;
        top: -2px;

        font-size: 0.9rem;
        color: $grey;
      }
    }

    .show-on-hover {
      transition: opacity 0.2s ease-in-out;

      opacity: 1;
      @include tablet {
        opacity: 0;
      }
    }

    .delete-icon {
      position: absolute;
      top: -10px;
      left: -10px;

      .delete {
        border: 0;
        background-color: black;
        color: white;
        border: 0;
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;
        max-width: 16px;
        max-height: 16px;

        &:hover {
          background-color: black;
        }
      }
    }

    :global(.switch .control-label) {
      padding: 0 !important;
    }
  }
</style>

<a class="asset-link" href={undefined} on:click={() => { if (!noFunds) dispatch('click') }}>
  <div class="card asset" class:disabled={!augmentedAsset.enabled} class:noFunds class:unsupported={!augmentedAsset.serviceData?.[`${type}Active`]}>
    <div class="card-content">
      <div class="media">
        <div class="media-left mr-3">
          <figure class="image"><img src="{augmentedAsset.coin.iconUrl}" alt="{augmentedAsset.symbol}"></figure>
        </div>
        <div class="media-content">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <div class="asset-symbol">
                  <span>{disp.dispSymbol ?? augmentedAsset.symbol}</span>
                  {#if !augmentedAsset.enabled}
                    &nbsp;<Tooltip label="Disabled"><i class="fa fa-power-off"></i></Tooltip>
                  {/if}
                  {#if augmentedAsset.serviceData?.unsupportedFeatures}
                    &nbsp;<Tooltip label="Has unsupported features"><i class="has-text-warning fa fa-question-circle"></i></Tooltip>
                  {/if}
                  {#if !augmentedAsset.serviceData?.[`${type}Active`]}
                    &nbsp;<Tooltip label="No longer supported"><i class="has-text-danger fa fa-exclamation-triangle"></i></Tooltip>
                  {:else if augmentedAsset.serviceData?.removeOnly}
                    &nbsp;<Tooltip label="Frozen reserves"><i class="has-text-danger fa fa-exclamation-triangle"></i></Tooltip>
                  {:else if type === 'collateral' && augmentedAsset.enabled && augmentedAsset.serviceData?.borrowLimit === 0}
                    &nbsp;<Tooltip label="Zero borrow limit"><i class="has-text-warning fa fa-exclamation-triangle"></i></Tooltip>
                  {/if}
                </div>
                <div style:width="max-content"><!-- This extra <div> just limits the width of the <hr> to the maximum width of the name and price -->
                  <div class="asset-name">{disp.name ?? augmentedAsset.coin.name}</div>
                  <hr />
                  <div class="asset-price"><span class:has-text-danger={$calculatorState.modifiedAssetPrices[augmentedAsset.symbol]}>{formatCurrency(augmentedAsset.price * quoteCoinEthPrice, $quoteCoin.symbol, undefined, '-', true)}</span></div>
                </div>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                {#if showAmounts}
                  <div class="asset-units">{formatCurrency(units ?? augmentedAsset.units, undefined, -6, '-', true)}</div>
                  <div class="asset-quote-value">
                    {#if !augmentedAsset.enabled}
                      {formatCurrency(0, $quoteCoin.symbol, undefined, '-', true)} <del class="is-hidden-mobile">{formatCurrency(units != null ? units * augmentedAsset.price * quoteCoinEthPrice : augmentedAsset.quoteValue, $quoteCoin.symbol, undefined, '-', true)}
                      </del>
                    {:else}
                      {formatCurrency(units != null ? units * augmentedAsset.price * quoteCoinEthPrice : augmentedAsset.quoteValue, $quoteCoin.symbol, undefined, '-', true)}
                    {/if}
                  </div>
                {/if}
                {#if noFunds}
                  <strong class="show-on-hover is-uppercase"><small>No funds!</small></strong>
                {:else if showEnable}
                  <span on:click|stopPropagation={() => {}} class="show-on-hover asset-enabled" title={{ true: 'Asset is always collateral', false: 'Asset is never collateral' }[augmentedAsset.serviceData?.collateralForcedEnableState] ?? 'Enable/disable'}>
                    <span>
                      {#if augmentedAsset.enabled}
                        {#if augmentedAsset.serviceData?.collateralForcedEnableState === true}
                          Always On
                        {:else}
                          Enabled
                        {/if}
                      {:else}
                        {#if augmentedAsset.serviceData?.collateralForcedEnableState === false}
                          Always Off
                        {:else}
                          Disable
                        {/if}
                      {/if}
                    </span>

                    <Switch size="is-small" checked={augmentedAsset.enabled} disabled={augmentedAsset.serviceData?.collateralForcedEnableState != null} on:input={e => dispatch('enable', e.target.checked)} />
                  </span>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {#if showRemove}
      <a class="delete-icon show-on-hover" href={undefined} on:click|stopPropagation={() => dispatch('remove')} title="Remove">
        <div class="delete"></div>
      </a>
    {/if}
  </div>
</a>
