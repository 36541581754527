<script>
  import { registeredWallets, activeWallet, getWalletName, mode, searchWallet, connectBlocknative, updateLiveData, updatingLiveData, setImportedWallet, walletConnecting, walletRestoring, walletAutoConnecting, unlinkConnectedWallet, unlinkImportedWallet } from '../stores/walletManager'
  import { Button, Icon, Field, Input, Dialog, Toast } from 'svelma-fixed'
  import Tooltip from './Tooltip.svelte'
  import WalletManager from './WalletManager.svelte'
  import WalletDetails from './WalletDetails.svelte'
  import { createLoadingStore, combineLoadingStores, loadingProps } from '../stores/loading'
  import { autoFocusInput } from '../lib/utils'
  import html from 'html-template-tag'
  import { ga } from '@beyonk/svelte-google-analytics'
  import NotificationsButton from './NotificationsButton.svelte'

  export let tooltipPosition = 'is-top'

  let searchFieldVisible = false

  $: if ($activeWallet) searchFieldVisible = false

  const blocknativeLoading = createLoadingStore()
  const searchAddressLoading = createLoadingStore()

  const loading = combineLoadingStores(blocknativeLoading, searchAddressLoading, walletConnecting, walletRestoring, walletAutoConnecting)

  function makeSearchFieldVisible () {
    searchFieldVisible = true
  }

  let searchAddressValue = ''
  $: if ($activeWallet) searchFieldVisible = false

  async function submitSearchAddress () {
    return await searchAddressLoading(async () => {
      const address = searchAddressValue.trim()
      if (!address) return
      try {
        await searchWallet(address)
      } catch (e) {
        console.error(e)

        if (e.code === 'invalid_address') {
          Toast.create({ message: 'Invalid address!', type: 'is-danger' })
        } else {
          const message = e.serverErrorMessage ?? e.message
          Dialog.alert({
            message: html`
              <p class="mb-3">
                The wallet address <strong>${address}</strong> could not be loaded!
              </p>
              <p>
                ${message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })

          ga.addEvent('error', { operation: 'load_wallet', error: message, address: 'addr:' + address })
        }
      }
    })
  }

  function onSearchFormFocusOut (event) {
    if (!event.currentTarget.contains(event.relatedTarget)) searchFieldVisible = false
  }

  async function triggerConnectBlocknative () {
    await blocknativeLoading(async () => {
      await connectBlocknative()
    })
  }

  let walletManagerModalActive = false
  let walletDetailsModalActive = false
</script>

<style lang="scss">
  section {
    display: inline-flex;
    line-height: 1.875rem;
    min-height: 1.875rem;
    text-align: left;
    gap: 0.25em;
    align-items: center;
    flex-wrap: wrap;

    &:has(.address-info-text) {
      line-height: unset;
    }
  }

  .wallet-type-icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    margin: -0.65em -0.25em;
    margin-left: 0.25rem;

    :global(.fa-wallet) {
      font-size: 1.5rem;
    }
  }

  .address-info .wallet-type-icon {
    font-size: 0.75rem;
    vertical-align: middle;
    margin: 0;
    transform: translateY(-2px);
  }

  .field-container {
    display: inline-block;
  }

  .search-field-container {
    width: 130px;
    transition: width 0.2s ease-in-out;

    &.searchFieldVisible {
      width: 200px;
    }

    > :global(button) {
      width: 100%;
      min-width: max-content;
      justify-content: space-between;
    }
  }

  .address-info {
    margin-left: 0.25em;
    margin-right: 0.25em;
    text-align: right;
  }

  .address-info-text {
    display: block;
    font-size: 63%;
    margin: -5px 0 -5px 4px;
  }
</style>

<section>
  {#if $activeWallet}
    <span class="address-info address">
      <Tooltip label="View Details and Balances" position={tooltipPosition}>
        <a href={undefined} on:click={() => (walletDetailsModalActive = true)}>
          {#if $mode === 'blocknative'}
            <span class="wallet-type-icon">
              {@html $activeWallet.walletTypeIconHtml ?? '<i class="fa fa-wallet"></i>'}
            </span>
          {:else}
            <Icon icon="ethereum" pack="fab" />
          {/if}
          {getWalletName($activeWallet)}
        </a>
      </Tooltip>
      {#if $mode === 'readOnly'}
        <br />
        <small class="address-info-text">Read-only. No access to funds.</small>
      {/if}
    </span>
    {#if $mode !== 'readOnly'}
      <Tooltip label="Switch to Read-Only Calculator" position={tooltipPosition}>
        <Button size="is-small" outlined on:click={() => setImportedWallet($activeWallet.address)} disabled={$updatingLiveData}><Icon size="is-small" icon="calculator" /></Button>
      </Tooltip>
    {/if}
    <Tooltip label="Sync Address" position={tooltipPosition}>
      <Button size="is-small" outlined on:click={() => updateLiveData(true)} loading={$updatingLiveData}><Icon size="is-small" icon="sync" /></Button>
    </Tooltip>
    {#if $mode === 'blocknative'}
      <Tooltip label="Disconnect Wallet" position={tooltipPosition}>
        <Button size="is-small" outlined on:click={() => unlinkConnectedWallet()} disabled={$updatingLiveData}><Icon size="is-small" icon="unlink" /></Button>
      </Tooltip>
    {:else}
      <Tooltip label="Unlink Address" position={tooltipPosition}>
        <Button size="is-small" outlined on:click={() => unlinkImportedWallet()} disabled={$updatingLiveData}><Icon size="is-small" icon="unlink" /></Button>
      </Tooltip>
    {/if}
  {:else}
    <div class="field-container search-field-container" class:searchFieldVisible>
      {#if searchFieldVisible}
        <form on:submit|preventDefault={submitSearchAddress} on:focusout={onSearchFormFocusOut} use:autoFocusInput>
          <Field>
            <Input expanded size="is-small" placeholder="0x..." bind:value={searchAddressValue} {...$searchAddressLoading ? { readonly: true } : {}} />
            {#if Object.keys($registeredWallets).length}
              <p class="control">
                <Tooltip label="Previous Wallets" position="is-bottom"><Button size="is-small" disabled={$loading} on:click={() => (walletManagerModalActive = true)}><Icon icon="list" /></Button></Tooltip>
              </p>
            {/if}
            <p class="control">
              <Button type="is-primary" size="is-small" nativeType="submit" {...loadingProps($loading, $searchAddressLoading)}><Icon icon="search" /></Button>
            </p>
          </Field>
        </form>
      {:else}
        <Button size="is-small" type="is-primary" outlined iconRight="search" on:click={makeSearchFieldVisible} {...loadingProps($loading, $searchAddressLoading)}>Search Address</Button>
      {/if}
    </div>
    <div class="field-container blocknative-field-container">
      <Button size="is-small" type="is-success" outlined iconRight="wallet" on:click={triggerConnectBlocknative} {...loadingProps($loading, $blocknativeLoading)}class="main-connect-wallet-button">Connect Wallet</Button>
    </div>
  {/if}

  <NotificationsButton tooltipPosition="is-bottom" />
</section>

{#if walletManagerModalActive}
  <WalletManager on:close={() => (walletManagerModalActive = false)} />
{/if}

{#if walletDetailsModalActive}
  <WalletDetails address={$activeWallet?.address} on:close={() => (walletDetailsModalActive = false)} />
{/if}
