import { qclone } from 'qclone'
import { calculateApy } from './apyCalc'

export function getCollateralOverallLiquidationThreshold (augmentedAssets) {
  return augmentedAssets.collateral.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.ratio * asset.serviceData?.liquidationThreshold ?? 0, 0)
}

export function getCircleColors (marketDropRatio) {
  if (marketDropRatio === null) {
    // These are reversed!
    return { circleOuterColor: '#2e94f4', circleInnerColor: '#2f5bde' }
  } else if (marketDropRatio > 0.4) {
    return { circleOuterColor: '#34a852', circleInnerColor: '#0bc245' }
  } else if (marketDropRatio > 0.15) {
    return { circleOuterColor: '#c27c0b', circleInnerColor: '#cd973c' }
  } else {
    return { circleOuterColor: '#c10b0b', circleInnerColor: '#ce3b3b' }
  }
}

/*
  calculateFullState({ assetPrices, quoteCoinPrices }, $quoteCoin, $markets, COREDATA) = { service, assets: { collateral, borrow }, stats, dirty, notificationsEnabled, calcValues: { collateralValue, etc. }, quoteCoinEthPrice, serviceAssetPrices }
*/

export function calculateFullState ({ assetPrices, quoteCoinPrices }, $quoteCoin, $markets, coreData) {
  const assets = qclone(coreData.assets)

  const quoteCoinEthPrice = quoteCoinPrices[$quoteCoin.symbol] ?? $quoteCoin.ethPrice

  for (const type of ['collateral', 'borrow']) {
    let totalEthValue = 0

    for (const asset of assets[type]) {
      asset.coin = $markets.coins[asset.symbol]
      asset.serviceData = asset.coin?.services[coreData.service]
      asset.price = assetPrices[coreData.service]?.[asset.symbol] ?? asset.serviceData?.price ?? asset.coin?.price ?? 0
      asset.ethValue = asset.units * asset.price
      asset.quoteValue = asset.ethValue * (quoteCoinEthPrice || 0)

      if (asset.enabled) totalEthValue += asset.ethValue
    }

    for (const asset of assets[type]) {
      asset.ratio = asset.enabled ? (asset.ethValue / totalEthValue) || 0 : null
    }
  }

  const calcValues = {}

  calcValues.borrowLimit = assets.collateral.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.quoteValue * (asset.quoteValue === Infinity ? 1e-10 : asset.serviceData?.borrowLimit ?? 0), 0)
  calcValues.supplyValue = assets.collateral.reduce((acc, asset) => acc + asset.quoteValue, 0)
  calcValues.collateralValue = assets.collateral.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.quoteValue, 0)
  calcValues.borrowValue = assets.borrow.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.quoteValue, 0)
  calcValues.thresholdCollateralValue = calcValues.borrowValue / getCollateralOverallLiquidationThreshold(assets)
  calcValues.marketDropRatio = 1 - calcValues.thresholdCollateralValue / calcValues.collateralValue

  const { circleOuterColor, circleInnerColor } = getCircleColors(!calcValues.collateralValue && !calcValues.borrowValue ? null : calcValues.marketDropRatio)
  calcValues.circleOuterColor = circleOuterColor
  calcValues.circleInnerColor = circleInnerColor

  if (coreData.stats?.netApy != null) {
    calcValues.netApyValue = coreData.stats.netApyEth * quoteCoinEthPrice
  }

  const doesAssetSupportStableBorrow = asset => !!asset.coin?.services[coreData.service]?.protocolRatesInfo?.stableBorrowApy
  const estimatedVariableApy = calculateApy([
    ...assets.collateral.map(asset => ({ type: 'supply', symbol: asset.symbol, ethValue: asset.ethValue })),
    ...assets.borrow.map(asset => ({ type: 'borrow', borrowType: 'variable', symbol: asset.symbol, ethValue: asset.ethValue }))
  ], coreData.service, $markets)
  const estimatedStableApy = calculateApy([
    ...assets.collateral.map(asset => ({ type: 'supply', symbol: asset.symbol, ethValue: asset.ethValue })),
    ...assets.borrow.map(asset => ({ type: 'borrow', borrowType: doesAssetSupportStableBorrow(asset) ? 'stable' : 'variable', symbol: asset.symbol, ethValue: asset.ethValue }))
  ], coreData.service, $markets)

  calcValues.estimatedNetApy = {
    variable: estimatedVariableApy.netApy,
    stable: estimatedStableApy.netApy,
    variableEth: estimatedVariableApy.netApyEth,
    stableEth: estimatedStableApy.netApyEth,
    variableQuote: estimatedVariableApy.netApyEth * quoteCoinEthPrice,
    stableQuote: estimatedStableApy.netApyEth * quoteCoinEthPrice
  }

  return { ...coreData, assets, quoteCoin: $quoteCoin, calcValues, quoteCoinEthPrice, serviceAssetPrices: assetPrices[coreData.service] }
}
