<script>
  import { getAddressShortLabel, registeredWallets } from '../stores/walletManager'
  import { createEventDispatcher } from 'svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Button, Field, Input, Switch } from 'svelma-fixed'
  import { createLoadingStore } from '../stores/loading'
  import { fetchNotificationSettings, updateNotificationSettings } from '../lib/notifications'
  import Loader from './Loader.svelte'
  import services from '../lib/services'
  import { AuthCancelledError } from '../lib/api'
  import { get } from 'svelte/store'
  import { CancelledError } from '../lib/errors'

  const dispatch = createEventDispatcher()

  export let address
  export let initialSettings
  let settings = initialSettings // Otherwise opening a new dialog would modify this!

  let formEl
  const loading = createLoadingStore()
  const saving = createLoadingStore()

  if (!address) throw new Error('Missing address')

  if (!settings) {
    loading(async () => {
      try {
        settings = await fetchNotificationSettings(address)
      } catch (e) {
        dispatch('close')
        if (e instanceof AuthCancelledError) return
        throw e
      }
    })
  }

  if (settings && settings.simpleNotifications.label == null) {
    settings.simpleNotifications.label = get(registeredWallets)[address]?.name ?? ''
  }

  async function submit () {
    formEl.reportValidity()
    if (!formEl.checkValidity()) return

    await saving(async () => {
      try {
        settings = await updateNotificationSettings(address, settings)
      } catch (e) {
        if (e instanceof CancelledError) return
        throw e
      }
      dispatch('close', settings)
    })
  }

  function focusEmailIfNeeded () {
    if (!settings) return
    if (!settings.simpleNotifications.email) formEl.querySelector('input[name="email"]').focus()
  }
</script>

<ModalDialog on:close title="Manage Notifications" closeable overflow on:introend={focusEmailIfNeeded}>
  {#if $loading}
    <Loader inline />
  {:else}
    <form on:submit|preventDefault={submit} bind:this={formEl}>
      <fieldset disabled={$saving}>
        {#each Object.values(services) as service}
          <Field>
            <Switch disabled={$saving} bind:checked={settings.simpleNotifications.enabled[service.id]}>Enable alerts for {service.name} safety margin</Switch>
          </Field>
        {/each}

        <small>Alerts are fired at thresholds of 40%, 15% and 5%.</small>

        <br /><br />

        <Field label="Custom Label">
          <Input bind:value={settings.simpleNotifications.label} placeholder={getAddressShortLabel(address)} />
        </Field>

        <Field label="Email Address">
          <Input required={Object.values(settings.simpleNotifications.enabled ?? {}).some(x => x)} bind:value={settings.simpleNotifications.email} name="email" type="email" />
        </Field>
      </fieldset>
    </form>
  {/if}

  <svelte:fragment slot="footer">
    <Button type="is-primary" on:click={submit} loading={$saving} disabled={$loading}>Save</Button>
    <Button on:click={() => dispatch('close')}>Cancel</Button>
  </svelte:fragment>
</ModalDialog>
