import { calculateApy } from '../../shared/lib/apyCalc'
import { get } from 'svelte/store'
import services, { DEFAULT_SERVICE_ICON } from '../lib/services'
import { quoteCoin } from '../stores/quoteCoin'
import markets, { DEFAULT_COIN_ICON, getDispSymbol } from '../stores/markets'
import { connectedWallet } from '../stores/walletManager'
import { qclone } from 'qclone'
import { calculateFullState } from './liquidationCalculator'
import { aaveV2ActionBorrow, aaveV2ActionDeposit, compoundV2ActionBorrow, compoundV2ActionDeposit, verifyMainnetChain } from './walletActions'
import AsyncLock from 'async-lock'

const lock = new AsyncLock()

export const gasRequirements = {
  approve: 50000,
  compoundV2: {
    enable: 98643,
    deposit: 170000,
    depositEth: 120000,
    borrow: 400000,
    borrowEth: 400000,
    approveDelegation: 0
  },
  aaveV2: {
    enable: 126099,
    deposit: 250000,
    depositEth: 210000,
    borrow: 600000,
    borrowEth: 370000,
    approveDelegation: 51866
  }
}

export function getLoanComparisonResults (borrowTokenFilter = ['DAI', 'TUSD', 'USDC', 'USDP', 'USDT'], borrowAmountEth, safetyMargin) {
  const results = []
  const $markets = get(markets)

  for (const symbol of borrowTokenFilter) {
    const borrowAsset = $markets.coins[symbol]
    if (!borrowAsset) continue

    for (const [service, borrowServiceInfo] of Object.entries(borrowAsset.services)) {
      if (!borrowServiceInfo.borrowActive || borrowServiceInfo.removeOnly || !borrowServiceInfo.price) continue

      for (const collateralAsset of Object.values($markets.coins)) {
        const collateralServiceInfo = collateralAsset.services[service]
        if (borrowTokenFilter.includes(collateralAsset.symbol) || !collateralServiceInfo || !collateralServiceInfo.collateralActive || collateralServiceInfo.removeOnly || !collateralServiceInfo.borrowLimit || !collateralServiceInfo.price) continue

        const collateralAmountEth = borrowAmountEth / collateralServiceInfo.liquidationThreshold / (1 - safetyMargin)

        const { netApyEth } = calculateApy([
          { type: 'supply', symbol: collateralAsset.symbol, ethValue: collateralAmountEth },
          { type: 'borrow', borrowType: 'variable', symbol: borrowAsset.symbol, ethValue: borrowAmountEth }
        ], service, $markets)

        results.push({
          service,
          safetyMargin,
          collateral: {
            symbol: collateralAsset.symbol,
            units: collateralAmountEth / collateralServiceInfo.price,
            ethValue: collateralAmountEth
          },
          borrow: {
            symbol: borrowAsset.symbol,
            units: borrowAmountEth / borrowServiceInfo.price,
            ethValue: borrowAmountEth
          },
          apy: {
            value: netApyEth / borrowAmountEth, // This calculation is different from how it normally is!
            ethValue: netApyEth
          },
          getDetails () {
            // This is calculated only when the details are opened
            if (!this._cachedDetails) {
              // We use ETH as quote coin here so that the calculated values are in ETH at this point
              const fullState = calculateFullState({ assetPrices: {}, quoteCoinPrices: {} }, $markets.quoteCoins.ETH, $markets, {
                service: this.service.id ?? this.service,
                assets: {
                  collateral: [{ symbol: this.collateral.symbol, units: this.collateral.units, enabled: true }],
                  borrow: [{ symbol: this.borrow.symbol, units: this.borrow.units, enabled: true }]
                },
                stats: {
                  netApy: this.apy.ethValue / this.collateral.ethValue,
                  netApyEth: this.apy.ethValue
                },
                dirty: false,
                notificationsEnabled: false
              })

              this._cachedDetails = {
                newState: fullState
              }
            }
            return this._cachedDetails
          },
          async getSteps (allowFake = false) {
            if (!allowFake) return null

            // TODO: Improve this so when cancelling, it won't pop up again and again if multiple results had open detail pane!
            await lock.acquire('mainnetCheck', async () => {
              await verifyMainnetChain()
            })

            const steps = []

            if (collateralAsset.symbol === 'ETH') {
              steps.push({ id: 'deposit', _gas: gasRequirements[service].depositEth })
            } else {
              steps.push({ id: 'approve', _gas: gasRequirements.approve })
              steps.push({ id: 'deposit', _gas: gasRequirements[service].deposit })
            }

            steps.push({ id: 'enable', _gas: gasRequirements[service].enable })

            if (borrowAsset.symbol === 'ETH') {
              steps.push({ id: 'approve', _gas: gasRequirements[service].approveDelegation })
              steps.push({ id: 'borrow', _gas: gasRequirements[service].borrowEth })
            } else {
              steps.push({ id: 'borrow', _gas: gasRequirements[service].borrow })
            }

            return steps
          }
        })
      }
    }
  }

  return results
}

// TODO: Combine this function into the main getLoanComparisonResults function as it no longer makes sense to separate those now that the data isn't loaded from the server anyway
export function augmentLoanComparisonResults (results, addToExisting, filters, hadDetailsOpen = new Set(), $markets = get(markets)) {
  if (!results) return results

  const $quoteCoin = get(quoteCoin)
  const $connectedWallet = get(connectedWallet)

  const liveData = $connectedWallet?.liveData
  if (addToExisting && liveData) {
    const stateByService = {}

    for (const service of Object.keys(services)) {
      // We use ETH as quote coin here so that the calculated values are in ETH at this point
      const fullState = calculateFullState({ assetPrices: {}, quoteCoinPrices: {} }, $markets.quoteCoins.ETH, $markets, {
        service,
        assets: {
          collateral: [
            ...qclone(liveData.services[service]?.collateralAssets ?? []),
            ...qclone(liveData.services[service]?.unusedSupplyAssets ?? [])
          ],
          borrow: qclone(liveData.services[service]?.borrowAssets ?? [])
        },
        stats: {
          netApy: liveData.services[service]?.stats?.netApy ?? null,
          netApyEth: liveData.services[service]?.stats?.netApyEth ?? null
        },
        dirty: false,
        notificationsEnabled: false
      })

      stateByService[service] = fullState
    }

    const balancesBySymbol = Object.fromEntries(liveData.balances.map(balance => [balance.symbol, balance]))

    results = results.map(result => {
      const serviceState = stateByService[result.service]

      result = qclone(result)

      if (serviceState.assets.collateral.some(asset => asset.symbol === result.collateral.symbol && !asset.enabled)) {
        result.hasDisabledCollateral = true
        return result
      }

      // First we need to understand the loan capacity of the existing collateral
      // Then we need to understand how much more collateral is needed to cover the loan, in the given new collateral asset
      const existingLoanCapacity = serviceState.assets.collateral.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.quoteValue * asset.serviceData?.liquidationThreshold ?? 0, 0)
      const requiredLoanCapacity = (serviceState.calcValues.borrowValue + result.borrow.ethValue) / (1 - result.safetyMargin)
      const missingLoanCapacity = Math.max(0, requiredLoanCapacity - existingLoanCapacity)
      const missingCollateralEthValue = missingLoanCapacity / $markets.coins[result.collateral.symbol]?.services[result.service]?.liquidationThreshold

      if (serviceState.calcValues.supplyValue) result.collateral.isChange = true
      result.collateral.ethValue = missingCollateralEthValue
      result.collateral.units = missingCollateralEthValue / $markets.coins[result.collateral.symbol]?.services[result.service]?.price

      if (!missingCollateralEthValue) result.collateral.symbol = null

      if (serviceState.calcValues.supplyValue) result.borrow.isChange = true

      const { netApyEth: extraNetApyEth } = calculateApy([
        ...result.collateral.symbol ? [{ type: 'supply', symbol: result.collateral.symbol, ethValue: result.collateral.ethValue }] : [],
        { type: 'borrow', borrowType: 'variable', symbol: result.borrow.symbol, ethValue: result.borrow.ethValue }
      ], result.service, $markets)

      if (serviceState.calcValues.supplyValue) {
        result.apy.value = extraNetApyEth / result.borrow.ethValue // This calculation is different from how it normally is!
        result.apy.ethValue = extraNetApyEth
      }

      let balance = balancesBySymbol[result.collateral.symbol]?.units ?? 0
      if (['aaveV2', 'compoundV3_USDC'].includes(result.service) && result.collateral.symbol === 'WETH') balance += balancesBySymbol.ETH?.units ?? 0
      result.hasSufficientBalance = result.collateral.units <= balance

      // TODO: Combine this with the original results getting function because it's weird that it's now in two places
      // This is calculated only when the details are opened
      result.getDetails = () => {
        const mergeAssets = (assets, newAssets) => {
          const result = qclone(assets)
          for (const newAsset of newAssets.filter(asset => asset.symbol && asset.units)) {
            const existingAsset = result.find(asset => asset.symbol === newAsset.symbol)
            if (existingAsset) {
              existingAsset.units += newAsset.units
            } else {
              result.push(newAsset)
            }
          }
          return result
        }

        if (!result._cachedDetails) {
          // We use ETH as quote coin here so that the calculated values are in ETH at this point
          const fullState = calculateFullState({ assetPrices: {}, quoteCoinPrices: {} }, $markets.quoteCoins.ETH, $markets, {
            service: result.service,
            assets: {
              collateral: mergeAssets(serviceState.assets.collateral, [{ symbol: result.collateral.symbol, units: result.collateral.units, enabled: true }]),
              borrow: mergeAssets(serviceState.assets.borrow, [{ symbol: result.borrow.symbol, units: result.borrow.units, enabled: true }])
            },
            stats: {},
            dirty: false,
            notificationsEnabled: false
          })

          const prevNetApyEth = serviceState.stats.netApyEth ?? serviceState.calcValues.estimatedNetApy.variableEth ?? 0
          fullState.stats.netApy = (prevNetApyEth + extraNetApyEth) / (serviceState.calcValues.supplyValue + missingCollateralEthValue)
          fullState.stats.netApyEth = prevNetApyEth + extraNetApyEth

          result._cachedDetails = {
            oldState: serviceState,
            newState: fullState
          }
        }
        return result._cachedDetails
      }

      if (result.hasSufficientBalance) {
        result.getSteps = async () => {
          // TODO: Improve this so when cancelling, it won't pop up again and again if multiple results had open detail pane!
          await lock.acquire('mainnetCheck', async () => {
            await verifyMainnetChain()
          })

          const steps = []

          if (result.collateral.units > 0) {
            if (result.service === 'compoundV2') {
              steps.push(...(await compoundV2ActionDeposit(result.collateral.symbol, result.collateral.units, true)).map(step => ({
                ...step,
                _gas: {
                  approve: gasRequirements.approve,
                  enable: gasRequirements.compoundV2.enable,
                  deposit: result.collateral.symbol === 'ETH' ? gasRequirements.compoundV2.depositEth : gasRequirements.compoundV2.deposit
                }[step.id]
              })))
            } else if (['aaveV2', 'compoundV3_USDC'].includes(result.service)) {
              if (result.collateral.symbol === 'WETH') {
                const wethUnits = Math.min(result.collateral.units, balancesBySymbol.WETH?.units ?? 0)
                const ethUnits = result.collateral.units - wethUnits

                if (wethUnits) {
                  steps.push(...(await aaveV2ActionDeposit('WETH', wethUnits, true)).map(step => ({
                    ...step,
                    _gas: {
                      approve: gasRequirements.approve,
                      enable: gasRequirements.aaveV2.enable,
                      deposit: gasRequirements.aaveV2.deposit
                    }[step.id]
                  })))
                }

                if (ethUnits) {
                  steps.push(...(await aaveV2ActionDeposit('ETH', ethUnits, true)).map(step => ({
                    ...step,
                    _gas: {
                      approve: gasRequirements.approve,
                      enable: gasRequirements.aaveV2.enable,
                      deposit: gasRequirements.aaveV2.depositEth
                    }[step.id]
                  })))
                }
              } else {
                steps.push(...(await aaveV2ActionDeposit(result.collateral.symbol, result.collateral.units, true)).map(step => ({
                  ...step,
                  _gas: {
                    approve: gasRequirements.approve,
                    enable: gasRequirements.aaveV2.enable,
                    deposit: result.collateral.symbol === 'ETH' ? gasRequirements.aaveV2.depositEth : gasRequirements.aaveV2.deposit
                  }[step.id]
                })))
              }
            }
          }

          if (result.service === 'compoundV2') {
            steps.push(...(await compoundV2ActionBorrow(result.borrow.symbol, result.borrow.units)).map(step => ({
              ...step,
              _gas: {
                borrow: result.borrow.symbol === 'ETH' ? gasRequirements.compoundV2.borrowEth : gasRequirements.compoundV2.borrow
              }[step.id]
            })))
          } else if (result.service === 'aaveV2') {
            steps.push(...(await aaveV2ActionBorrow(result.borrow.symbol, result.borrow.units, 'Variable')).map(step => ({
              ...step,
              _gas: {
                approve: gasRequirements.aaveV2.approveDelegation,
                borrow: result.borrow.symbol === 'ETH' ? gasRequirements.aaveV2.borrowEth : gasRequirements.aaveV2.borrow
              }[step.id]
            })))
          }

          return steps
        }
      }

      return result
    })
  }

  return results.filter((result, i) => {
    if (!result.collateral.symbol && results.findIndex(r => r.service === result.service && r.borrow.symbol === result.borrow.symbol && !r.collateral.symbol) !== i) return false
    if (!filters.tokensToBorrow.includes(result.borrow.symbol.replace(/^WETH$/, 'ETH'))) return false
    if (filters.tokensToDeposit.length && result.collateral.symbol && !filters.tokensToDeposit.includes(result.collateral.symbol.replace(/^WETH$/, 'ETH'))) return false
    if (filters.services && result.service !== filters.services) return false
    return true
  }).sort((a, b) => {
    let result = 0
    if (filters.sortBy === 'collateralAmount') result = a.collateral.ethValue - b.collateral.ethValue
    if (filters.sortBy === 'collateral') result = (a.collateral.symbol ?? '').localeCompare(b.collateral.symbol ?? '')
    if (filters.sortBy === 'borrow') result = a.borrow.symbol.localeCompare(b.borrow.symbol)
    if (filters.sortBy === 'service') result = a.service.localeCompare(b.service)
    if (filters.sortBy === 'apy') result = b.apy.value - a.apy.value

    return (a.hasDisabledCollateral ? 1 : 0) - (b.hasDisabledCollateral ? 1 : 0) ||
      result ||
      a.service.localeCompare(b.service) ||
      a.collateral.ethValue - b.collateral.ethValue ||
      (a.collateral.symbol ?? '').localeCompare(b.collateral.symbol ?? '') ||
      a.borrow.symbol.localeCompare(b.borrow.symbol) ||
      b.apy.value - a.apy.value
  }).map(result => {
    const newResult = {
      service: services[result.service] ?? { id: result.service, name: result.service, icon: DEFAULT_SERVICE_ICON },
      collateral: {
        ...result.collateral,
        dispSymbol: getDispSymbol(result.collateral.symbol, result.service),
        quoteValue: result.collateral.ethValue * $quoteCoin.ethPrice,
        iconUrl: $markets.coins[result.collateral.symbol]?.iconUrl ?? DEFAULT_COIN_ICON
      },
      borrow: {
        ...result.borrow,
        dispSymbol: getDispSymbol(result.borrow.symbol, result.service),
        quoteValue: result.borrow.ethValue * $quoteCoin.ethPrice,
        iconUrl: $markets.coins[result.borrow.symbol]?.iconUrl ?? DEFAULT_COIN_ICON
      },
      apy: {
        ...result.apy,
        quoteValue: result.apy.ethValue * $quoteCoin.ethPrice
      },
      hasDisabledCollateral: result.hasDisabledCollateral,
      hasSufficientBalance: result.hasSufficientBalance,
      getDetails: result.getDetails ?? (() => ({})),
      getSteps: result.getSteps ?? (() => [])
    }

    newResult._key = [newResult.service.id, newResult.collateral.symbol, newResult.borrow.symbol].join(':')
    if (hadDetailsOpen.has(newResult._key)) newResult._detailsOpen = true

    return newResult
  })
}
