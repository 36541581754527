<script>
  export let colorEasing = false
</script>

<style lang="scss">
  figure {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--size, 225px);
    height: var(--size, 225px);
    border-radius: 50%;
    border: var(--thickness, 1px) solid var(--border-color, currentColor);
    background-color: var(--bg-color, transparent);
    padding: var(--padding, calc(var(--size, 225px) / 7));

    &.colorEasing {
      transition: background-color 0.2s, border-color 0.2s;
    }
  }
</style>

<figure class:colorEasing>
  <slot />
</figure>
