<script>
  import { Tabs } from 'svelma-fixed'
  import { onMount } from 'svelte'

  export let style = 'is-boxed'
  export let size

  let tabs

  export let initialTabIndex

  onMount(() => {
    if (initialTabIndex !== undefined) {
      tabs.setActive(initialTabIndex)
    }
  })
</script>

<style lang="scss">
  section {
    display: contents;

    > :global(.tabs-wrapper > .tabs) {
      margin-bottom: 0;
    }

    > :global(.tabs-wrapper > .tab-content > .tab) {
      border: 1px solid $border;
      border-top: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 1rem;
      min-height: var(--min-scrollbox-height, none);
      max-height: var(--max-scrollbox-height, 50vh);
      overflow: auto;
    }
  }
</style>

<section>
  <Tabs {style} {size} bind:this={tabs}>
    <slot />
  </Tabs>
</section>
