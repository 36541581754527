<script>
  import { Button, Icon, Switch } from 'svelma-fixed'
  import Tooltip from './Tooltip.svelte'
  import Dropdown from './Dropdown.svelte'
  import { mode, activeWalletAddress } from '../stores/walletManager'
  import { notificationsOperationLoading, enableNotifications, disableNotifications, showNotificationSettingsDialog } from '../lib/notifications'
  import { calculatorState } from '../stores/calculatorState'
  import services from '../lib/services'
  import { ga } from '@beyonk/svelte-google-analytics'

  export let tooltipPosition = 'is-top'

  function onNotificationsChange (event, service) {
    if (event.target.checked) {
      ga.addEvent('notifications_enable', { service, address: 'addr:' + $activeWalletAddress })
      enableNotifications(service)
    } else {
      ga.addEvent('notifications_disable', { service, address: 'addr:' + $activeWalletAddress })
      disableNotifications(service)
    }
  }

  function manageButtonClick () {
    ga.addEvent('notifications_manage', { address: 'addr:' + $activeWalletAddress })
    showNotificationSettingsDialog()
  }
</script>

<Dropdown class="is-right">
  <Tooltip slot="trigger" label="Notifications" position={tooltipPosition} let:open active={!open}>
    <Button size="is-small" outlined on:click={() => ga.addEvent('notifications_dropdown', { address: 'addr:' + $activeWalletAddress })}><Icon size="is-small" icon="bell" pack="far" /></Button>
  </Tooltip>

  {#if $mode === 'blocknative'}
    {#each Object.values(services) as service}
      <div class="dropdown-item keep-dropdown-open">
        <Switch size="is-small" bind:checked={$calculatorState.services[service.id].notificationsEnabled} disabled={$notificationsOperationLoading} on:input={e => onNotificationsChange(e, service.id)}>
          {service.name} alerts
        </Switch>
      </div>
    {/each}

    <div class="dropdown-item">
      <Button class="is-fullwidth" size="is-small" color="is-light" loading={$notificationsOperationLoading} on:click={manageButtonClick}>Manage Notifications</Button>
    </div>
  {:else}
    <div class="dropdown-item">
      <Icon icon="info-circle" />
      To enable notifications, please <a href={undefined} on:click={() => document.querySelector('.main-connect-wallet-button')?.click()}>connect your wallet</a>!
    </div>
  {/if}
</Dropdown>
