<svelte:options accessors />

<script>
  import { formatCurrency } from '../lib/utils'
  import { compoundV2ActionEnableDisable, compoundV2ActionDeposit, ALL, compoundV2ActionWithdraw, compoundV2ActionBorrow, compoundV2ActionRepay, aaveV2ActionEnableDisable, aaveV2ActionDeposit, aaveV2ActionWithdraw, aaveV2ActionBorrow, aaveV2ActionRepay, compoundV3ActionDeposit, compoundV3ActionWithdraw, compoundV3ActionBorrow, compoundV3ActionRepay, aaveV3ActionEnableDisable, aaveV3ActionDeposit, aaveV3ActionWithdraw, aaveV3ActionBorrow, aaveV3ActionRepay } from '../lib/walletActions'
  import TxQueue from './TxQueue.svelte'
  import WalletActionDialog from './WalletActionDialog.svelte'
  import { selectedService } from '../stores/calculatorState'
  import services from '../lib/services'
  import { updateLiveData, updatingLiveData } from '../stores/walletManager'
  import markets, { getDispSymbol } from '../stores/markets'

  let counter = 0
  let props = {}

  function open (newMode, newData = {}, newProps = {}) {
    close()

    mode = newMode
    data = newData
    props = newProps
    counter++
  }

  export function deposit (symbol, isNew = false) {
    const collateralForcedEnableState = $markets.coins[symbol].services[$selectedService]?.collateralForcedEnableState
    open('deposit', { symbol, enable: isNew ? collateralForcedEnableState ?? true : undefined, useUnwrapped: true }, { hasEnableCheckbox: isNew, isEnableStateForced: collateralForcedEnableState != null, isNew })
  }

  export function disable (symbol) {
    open('disable', { symbol })
  }

  export function enable (symbol) {
    open('enable', { symbol })
  }

  export function withdraw (symbol) {
    open('withdraw', { symbol, useUnwrapped: true })
  }

  export function borrow (symbol, isNew = false) {
    open('borrow', { symbol, useUnwrapped: true, interestRateMode: 'Variable' }, { hasInterestModeSelector: !!$markets.coins[symbol].services[$selectedService]?.protocolRatesInfo?.stableBorrowApy, isNew })
  }

  export function repay (symbol) {
    open('repay', { symbol, useUnwrapped: true })
  }

  const actions = { deposit, disable, enable, withdraw, borrow, repay }

  export let mode = null
  export let data = {}

  let txQueueData = null // { title, steps }

  function onActionDialogClose () {
    if (!txQueueData) mode = null
  }

  export function close () {
    mode = null
    data = {}
    txQueueData = null

    if (!$updatingLiveData) updateLiveData()
  }

  function setTxQueue (title, steps) {
    txQueueData = { title, steps }
  }

  function getFinalSymbol (data) {
    // TODO: This, again, is a hack.
    if (data.useUnwrapped && getDispSymbol(data.symbol, $selectedService).startsWith('(W)')) {
      return data.symbol.replace(/^W/, '')
    } else {
      return data.symbol
    }
  }
</script>

{#key counter}
  {#if mode}
    {#if txQueueData}
      <TxQueue title={txQueueData.title} steps={txQueueData.steps} on:close={close} on:done={() => updateLiveData()} />
    {:else}
      {#if mode === 'deposit'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Deposit"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionDeposit(data.symbol, data.all ? ALL : data.amount, data.enable)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionDeposit((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount, data.enable)
            } else if ($selectedService === 'compoundV3_USDC') {
              steps = await compoundV3ActionDeposit('USDC', (data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount, data.enable)
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionDeposit((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount, data.enable)
            }
            setTxQueue(`Depositing ${data.all ? 'all' : formatCurrency(data.amount, undefined, -6, '-', true)} ${getFinalSymbol(data)} to ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Deposit'}
          getPreviewState={(baseState, data) => {
            if (data.symbol) {
              let asset = baseState.assets.collateral.find(asset => asset.symbol === data.symbol)
              if (!asset) {
                asset = { symbol: data.symbol, units: 0, enabled: data.enable ?? true }
                baseState.assets.collateral.push(asset)
              }
              asset.units += data.amount
            }
            return baseState
          }}
          hasAmount
          assetType="collateral"
          source="balance"
          {...props}
        />
      {:else if mode === 'disable'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Disable"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionEnableDisable(data.symbol, false)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionEnableDisable(data.symbol, false)
            } else if ($selectedService === 'compoundV3_USDC') {
              throw new Error('This service does not support enabling/disabling!')
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionEnableDisable(data.symbol, false)
            }
            setTxQueue(`Disabling ${data.symbol} as collateral in ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Disable'}
          getPreviewState={(baseState, data) => {
            baseState.assets.collateral.find(asset => asset.symbol === data.symbol).enabled = false
            return baseState
          }}
          assetType="collateral"
          operationLabel="You are disabling {data.symbol} as collateral."
          {...props}
        />
      {:else if mode === 'enable'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Enable"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionEnableDisable(data.symbol, true)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionEnableDisable(data.symbol, true)
            } else if ($selectedService === 'compoundV3_USDC') {
              throw new Error('This service does not support enabling/disabling!')
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionEnableDisable(data.symbol, true)
            }
            setTxQueue(`Enabling ${data.symbol} as collateral in ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Enable'}
          getPreviewState={(baseState, data) => {
            baseState.assets.collateral.find(asset => asset.symbol === data.symbol).enabled = true
            return baseState
          }}
          assetType="collateral"
          operationLabel="You are enabling {data.symbol} as collateral."
          {...props}
        />
      {:else if mode === 'withdraw'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Withdraw"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionWithdraw(data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionWithdraw((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'compoundV3_USDC') {
              steps = await compoundV3ActionWithdraw('USDC', (data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionWithdraw((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            }
            setTxQueue(`Withdrawing ${data.all ? 'all' : formatCurrency(data.amount, undefined, -6, '-', true)} ${getFinalSymbol(data)} from ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Withdraw'}
          getPreviewState={(baseState, data) => {
            const asset = baseState.assets.collateral.find(asset => asset.symbol === data.symbol)
            asset.units -= Math.min(asset.units, data.amount)
            return baseState
          }}
          hasAmount
          assetType="collateral"
          source="supply"
          target="balance"
          {...props}
        />
      {:else if mode === 'borrow'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Borrow"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionBorrow(data.symbol, data.amount)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionBorrow((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.amount, data.interestRateMode)
            } else if ($selectedService === 'compoundV3_USDC') {
              steps = await compoundV3ActionBorrow('USDC', (data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.amount, data.interestRateMode)
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionBorrow((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.amount, data.interestRateMode)
            }
            setTxQueue(`Borrowing ${formatCurrency(data.amount, undefined, -6, '-', true)} ${getFinalSymbol(data)} from ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Borrow'}
          getPreviewState={(baseState, data) => {
            if (data.symbol) {
              let asset = baseState.assets.borrow.find(asset => asset.symbol === data.symbol)
              if (!asset) {
                asset = { symbol: data.symbol, units: 0, enabled: true }
                baseState.assets.borrow.push(asset)
              }
              asset.units += data.amount
            }
            return baseState
          }}
          hasAmount
          assetType="borrow"
          source="borrowLimitLeft"
          target="balance"
          allButtonRatio={0.8}
          {...props}
        />
      {:else if mode === 'repay'}
        <WalletActionDialog
          actionId={mode}
          {actions}
          title="Repay"
          on:close={onActionDialogClose}
          bind:data
          onSubmit={async data => {
            let steps
            if ($selectedService === 'compoundV2') {
              steps = await compoundV2ActionRepay(data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'aaveV2') {
              steps = await aaveV2ActionRepay((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'compoundV3_USDC') {
              steps = await compoundV3ActionRepay('USDC', (data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            } else if ($selectedService === 'aaveV3') {
              steps = await aaveV3ActionRepay((data.useUnwrapped && data.symbol === 'WETH') ? 'ETH' : data.symbol, data.all ? ALL : data.amount)
            }
            setTxQueue(`Repaying ${data.all ? 'all borrowed' : formatCurrency(data.amount, undefined, -6, '-', true)} ${getFinalSymbol(data)} to ${services[$selectedService]?.name}`, steps)
          }}
          getSubmitLabel={data => 'Repay'}
          getPreviewState={(baseState, data) => {
            const asset = baseState.assets.borrow.find(asset => asset.symbol === data.symbol)
            asset.units -= Math.min(asset.units, data.amount)
            return baseState
          }}
          hasAmount
          assetType="borrow"
          source="borrow"
          secondarySource="balance"
          {...props}
        />
      {/if}
    {/if}
  {/if}
{/key}
