<script>
  import { slide } from 'svelte/transition'
  import { Button } from 'svelma-fixed'
  import { createLoadingStore, combineLoadingStores, loadingProps } from '../stores/loading'
  import { activeWalletAddress, walletConnecting, walletRestoring, walletAutoConnecting, connectBlocknative } from '../stores/walletManager'

  const blocknativeLoading = createLoadingStore()

  const loading = combineLoadingStores(blocknativeLoading, walletConnecting, walletRestoring, walletAutoConnecting)

  async function triggerConnectBlocknative () {
    await blocknativeLoading(async () => {
      await connectBlocknative()
    })
  }
</script>

<style lang="scss">
  div {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5em;
  }
</style>

{#if !$activeWalletAddress}
  <div transition:slide|local>
    <Button size="is-large" type="is-success" outlined iconRight="wallet" on:click={triggerConnectBlocknative} {...loadingProps($loading, $blocknativeLoading)}>Connect Wallet</Button>
  </div>
{/if}