<script>
  import ErrorBox from '../components/ErrorBox.svelte'
  import Loader from '../components/Loader.svelte'

  if (window.Redoc) location.reload() // We get trouble with redoc-try if we don't reload the page!

  async function loadRedocScript () {
    if (window.Redoc) return
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = '/build/js/redoc.standalone.js'
      script.onload = resolve
      script.onerror = e => reject(new Error('Failed to load Redoc script!'))
      document.body.appendChild(script)
    })
  }

  async function loadRedocTryScript () {
    if (window.initTry) return
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = '/build/js/try.js'
      script.onload = resolve
      script.onerror = e => reject(new Error('Failed to load Redoc Try script!'))
      document.body.appendChild(script)
    })
  }

  function redoc (element) {
    const url = '/build/misc/apiV1.openapi.json'
    window.initTry({
      openApi: url,
      redocVersion: '2.1.3',
      redocOptions: [url, {
        scrollYOffset: '#navbarMenu',
        theme: {
          breakpoints: {
            large: '120rem' // Avoid issues with the Swagger UI getting too narrow
          },
          colors: {
            primary: {
              main: '#2f95f4'
            }
          },
          typography: {
            fontFamily: 'inherit',
            headings: {
              fontFamily: 'var(--font-callout)',
              fontWeight: '700'
            }
          }
        },
        hideDownloadButton: false,
        hideLoading: false,
        expandResponses: '200'
      }, element],
      authBtnPosSelector: '[data-role="redoc-description"]:eq(0)'
    })
  }
</script>

<style lang="scss">
  main {
    --font-callout: #{$family-callout};

    // Hide the "Powered by" container which overlays footer when scrolling down all the way!
    :global(.scrollbar-container > ul[role="menu"] + div) {
      display: none;
    }

    // Undo Bulma styling
    :global(.number) {
      align-items: inherit;
      background-color: inherit;
      border-radius: inherit;
      display: inherit;
      font-size: inherit;
      height: inherit;
      justify-content: inherit;
      margin-right: inherit;
      min-width: inherit;
      padding: inherit;
      text-align: inherit;
      vertical-align: inherit;
    }

    :global(code) {
      background-color: inherit;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding: inherit;
    }

    :global(.setAuth) {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }
</style>

<svelte:head>
	<title>API Docs - WEHODL</title>
</svelte:head>

{#await Promise.all([loadRedocScript(), loadRedocTryScript()])}
  <Loader />
{:then}
  <main use:redoc>
    <Loader />
  </main>

  <!-- This is a fix for redoc-try's authorize not working once credentials were already entered because the class will change from unlocked to locked! -->
  <div style:display="none" class="swagger-ui">
    <div class="auth-wrapper">
      <button class="authorize unlocked" on:click={() => document.querySelector('#swagger-ui .auth-wrapper .authorize').click()}></button>
    </div>
  </div>
{:catch error}
  <ErrorBox>{error.message}</ErrorBox>
{/await}
