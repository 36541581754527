import { persistentWritable } from './persistentStore'
import { get, derived } from 'svelte/store'
import markets from './markets'
// import { getCurrency } from 'locale-currency'

export const quoteCoinSymbol = persistentWritable('wehodlQuoteCoinSymbol', null)

export function reset ($markets = get(markets)) {
  if (!$markets) {
    quoteCoinSymbol.set(null)
  } else {
    const navigatorCurrency = 'USD' // getCurrency(window.navigator.language) - removed because the UI for currency change was hidden
    const quoteCoin = $markets.quoteCoins[navigatorCurrency] || $markets.quoteCoins.USD || Object.values($markets.quoteCoins)[0]
    quoteCoinSymbol.set(quoteCoin.symbol)
  }
}

markets.subscribe($markets => {
  if ($markets && !get(quoteCoinSymbol)) {
    reset($markets)
  }
})

export const quoteCoin = derived([quoteCoinSymbol, markets], ([$quoteCoinSymbol, $markets]) => {
  if (!$markets || quoteCoinSymbol === null) return null
  const quoteCoin = $markets.quoteCoins[$quoteCoinSymbol]
  if (!quoteCoin) {
    reset($markets)
    return $markets.quoteCoins[get(quoteCoinSymbol)]
  } else {
    return quoteCoin
  }
})
