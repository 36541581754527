import { connectedWalletAddress, toChecksumAddress, currentProvider, ensureNetwork } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import markets from '../../stores/markets'
import services from '../services'
import * as ethers from 'ethers'
import erc20Abi from '../abi/erc20Abi.json'

export * from './compoundV2'
export * from './aaveV2'
export * from './compoundV3'
export * from './aaveV3'

export const ALL = Symbol('ALL')
export const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const HALF_MAX_UINT256 = '0x8000000000000000000000000000000000000000000000000000000000000000'

let provider
currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

export async function verifyMainnetChain () {
  await ensureNetwork('mainnet')
}

export async function waitForTxHashOnly (sendPromise, setWaiting = () => {}) {
  setWaiting(true)
  try {
    return await Promise.race([
      sendPromise.then(tx => tx.hash ?? tx),
      ...sendPromise.once ? [new Promise(resolve => sendPromise.once('transactionHash', resolve))] : []
    ])
  } finally {
    setWaiting(false)
  }
}

const decimalsCache = new Map()
export async function getDecimals (symbol) {
  if (symbol === 'ETH') return 18
  if (decimalsCache.has(symbol)) return decimalsCache.get(symbol)

  const contract = await getContract(symbol)
  const decimals = await contract.decimals()

  decimalsCache.set(symbol, decimals)
  return decimals
}

export async function getAmountForDisplay (symbol, amount) {
  if (typeof amount === 'number') return amount
  const decimals = await getDecimals(symbol)
  return Number(amount) / 10 ** decimals
}

export async function getAmountWithCorrectDecimals (symbol, amount) {
  if (typeof amount !== 'number') return amount
  const decimals = await getDecimals(symbol)
  return Number(amount).toFixed(decimals)
}

export async function getAmountInSmallestUnit (symbol, amount) {
  if (typeof amount !== 'number') return String(amount)
  const decimals = await getDecimals(symbol)
  return (Number(amount) * 10 ** decimals).toFixed(0)
}

export async function getContract (symbolOrContractAddress) {
  if (!provider) throw new Error('Ethereum provider is not available')

  const contractAddress = symbolOrContractAddress.startsWith('0x') ? symbolOrContractAddress : get(markets).coins[symbolOrContractAddress]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbolOrContractAddress}`)

  const contract = await new ethers.Contract(contractAddress, erc20Abi, provider.getSigner())
  return contract
}

export async function getAllowance (symbolOrContractAddress, address) {
  const contract = await getContract(symbolOrContractAddress)
  return await contract.allowance(get(connectedWalletAddress), address)
}

export async function approve (symbolOrContractAddress, address, amount = MAX_UINT256) {
  const contract = await getContract(symbolOrContractAddress)
  return contract.approve(address, amount)
}

export async function getErc20Balance (symbolOrContractAddress) {
  const contract = await getContract(symbolOrContractAddress)
  return await contract.balanceOf(get(connectedWalletAddress))
}

export function getApproveStep (symbol, addressOrFn, service, descriptionShort = `Approve ${symbol} debit`, descriptionLong = `Approve ${symbol} debit by ${services[service]?.name ?? service}`) {
  if (typeof addressOrFn === 'string') {
    const address = addressOrFn
    addressOrFn = async () => ({ promise: approve(symbol, address) })
  }

  return {
    id: 'approve',
    description: descriptionShort,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const { promise } = await addressOrFn() ?? {}
      if (!promise) return null
      const hash = await waitForTxHashOnly(promise, setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service,
          type: 'approve',
          symbol
        },
        icon: 'signature',
        description: descriptionLong,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  }
}
