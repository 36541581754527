import { apiCall } from './api'
import { CancelledError } from './errors'
import { createLoadingStore } from '../stores/loading'
import { calculatorState } from '../stores/calculatorState'
import { activeWalletAddress, updateLiveData } from '../stores/walletManager'
import services from './services'
import { get } from 'svelte/store'
import dialogs from '../stores/dialogs'
import NotificationSettingsDialog from '../components/NotificationSettingsDialog.svelte'
import EmailVerificationDialog from '../components/EmailVerificationDialog.svelte'
import uri from 'uri-tag'

export const notificationsOperationLoading = createLoadingStore()

export function updateCoreData (settings) {
  calculatorState.update($calculatorState => {
    for (const service of Object.keys(services)) {
      if ($calculatorState.services[service]) {
        $calculatorState.services[service].notificationsEnabled = settings?.simpleNotifications?.enabled[service] ?? false
      }
    }
    return $calculatorState
  })
}

export async function fetchNotificationSettings (address, doUpdateCoreData = true) {
  return await notificationsOperationLoading(async () => {
    const settings = await apiCall('GET', uri`/api/notifications/${address}`, {}, true)
    if (doUpdateCoreData) updateCoreData(settings)
    return settings
  })
}

export async function updateNotificationSettings (address, settings, doUpdateCoreData = true) {
  settings = { ...settings } // Because we may add OTP
  return await notificationsOperationLoading(async () => {
    while (true) {
      try {
        settings = await apiCall('PATCH', uri`/api/notifications/${address}`, settings, true)
        break
      } catch (e) {
        if (e.code === 'email_verification_required' || e.code === 'invalid_email_verification_code') {
          const lastVerificationEmail = e.responseBody.lastVerificationEmail
          const email = settings.simpleNotifications.email
          settings.emailVerificationCode = await showEmailVerificationDialog(email, new Date(lastVerificationEmail), e.code === 'invalid_email_verification_code')
          if (!settings.emailVerificationCode) throw new CancelledError('User cancelled email verification')
          if (settings.emailVerificationCode === 'RESEND') delete settings.emailVerificationCode
        } else {
          throw e
        }
      }
    }
    if (doUpdateCoreData) updateCoreData(settings)
    return settings
  })
}

export async function enableNotifications (service) {
  return await notificationsOperationLoading(async () => {
    try {
      const address = get(activeWalletAddress)
      if (!address) throw new Error('No active wallet')

      const settings = await fetchNotificationSettings(address, false)
      settings.simpleNotifications.enabled[service] = true
      updateCoreData(settings)

      if (!settings.simpleNotifications.email) {
        if (!await showNotificationSettingsDialog(address, settings)) {
          // Try to restore old value for display
          await updateLiveData()
        }
      } else {
        return await updateNotificationSettings(address, settings)
      }
    } catch (e) {
      // Try to restore old value for display
      try {
        await updateLiveData()
      } catch {}

      if (e instanceof CancelledError) return
      throw e
    }
  })
}

export async function disableNotifications (service) {
  return await notificationsOperationLoading(async () => {
    try {
      const address = get(activeWalletAddress)
      if (!address) throw new Error('No active wallet')

      const settings = await fetchNotificationSettings(address, false)
      settings.simpleNotifications.enabled[service] = false
      updateCoreData(settings)

      return await updateNotificationSettings(address, settings)
    } catch (e) {
      // Try to restore old value for display
      try {
        await updateLiveData()
      } catch {}

      if (e instanceof CancelledError) return
      throw e
    }
  })
}

export async function showNotificationSettingsDialog (address = get(activeWalletAddress), settings) {
  return await notificationsOperationLoading(async () => {
    try {
      if (!address) throw new Error('No active wallet')
      return await dialogs.open(NotificationSettingsDialog, { address, initialSettings: settings })
    } catch (e) {
      if (e instanceof CancelledError) return
      throw e
    }
  })
}

export async function showEmailVerificationDialog (email, lastVerificationEmail, previousAttemptWasWrong = false) {
  return await dialogs.open(EmailVerificationDialog, { email, lastVerificationEmail, previousAttemptWasWrong })
}
