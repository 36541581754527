import { get, derived } from 'svelte/store'
import { apiCall } from '../lib/api'
import { registeredWallets, getProvider, mode, toChecksumAddress, connectedWalletAddress } from './walletManager'
import { onboard } from './onboard'
import { createLoadingStore } from './loading'
import { persistentWritable } from './persistentStore'
import jwt from 'jsonwebtoken'
import { tick } from 'svelte'

export const authTokens = persistentWritable('wehodlAuthTokens', {}, true)

registeredWallets.subscribe($registeredWallets => {
  authTokens.update($authTokens => {
    for (const address of Object.keys($authTokens)) {
      if (!$registeredWallets[address]) delete $authTokens[address]
    }
    return $authTokens
  })
})

// Automatic cleanup of expired auth tokens (which will expire within the next 2 minutes)
function cleanup () {
  authTokens.update($authTokens => {
    for (const [address, token] of Object.entries($authTokens)) {
      try {
        const { exp } = jwt.decode(token)
        if (exp * 1000 < Date.now() - 120000) {
          delete $authTokens[address]
        }
      } catch (e) {
        console.warn(`Invalid auth token for ${address}: "${token}"`, e)
        delete $authTokens[address]
      }
    }
    return $authTokens
  })
}

cleanup()
setInterval(cleanup, 60000)

export const currentAuthToken = derived([authTokens, connectedWalletAddress], ([$authTokens, $connectedWalletAddress]) => ($connectedWalletAddress ? $authTokens[$connectedWalletAddress] : null) ?? null)

export const authenticateLoading = createLoadingStore()

export async function authenticate () {
  return await authenticateLoading(async () => {
    const provider = getProvider(true)
    const signer = provider.getSigner()
    const address = await signer.getAddress()

    const { message } = await apiCall('POST', '/api/auth/prepare', { address })

    const { update, dismiss } = get(mode) === 'blocknative'
      ? onboard.state.actions.customNotification({
        type: 'pending',
        message: 'Verification pending. Confirm the signing request in your wallet to continue.',
        autoDismiss: 0
      })
      : { update: () => {}, dismiss: () => {} }

    let signature
    try {
      signature = await signer.signMessage(message)

      update({
        eventCode: 'authSuccess',
        message: 'Wallet ownership verified!',
        type: 'success',
        autoDismiss: 5000
      })
      setTimeout(dismiss, 5000) // Not sure why autoDismiss is broken...?
    } catch (e) {
      if (e.code === 'ACTION_REJECTED' || e.code === 4001) {
        dismiss()
        return null
      }

      update({
        eventCode: 'authFailed',
        message: 'Failed to verify wallet ownership! Please try again.',
        type: 'error',
        autoDismiss: 5000
      })
      setTimeout(dismiss, 5000) // Not sure why autoDismiss is broken...?
      throw e
    }

    const result = await apiCall('POST', '/api/auth/submit', { address, message, signature })
    authTokens.update($authTokens => {
      $authTokens[toChecksumAddress(address)] = result.authToken
      return $authTokens
    })
    await tick() // Ensure all stores are updated correctly
    return result
  })
}

export function signOut (address) {
  authTokens.update($authTokens => {
    delete $authTokens[address]
    return $authTokens
  })
}
