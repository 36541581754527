// Positions is an array like this:
// { type: 'supply'/'borrow', borrowType: 'stable'/'variable'/null, symbol: 'ETH', ethValue: 1.2345, userApy: 0.1234/null }
export function calculateApy (positions, service, markets) {
  let positivePart = 0
  let negativePart = 0
  let totalSupply = 0

  for (const position of positions) {
    const serviceData = markets.coins[position.symbol]?.services[service]

    if (position.type === 'supply') {
      if (serviceData) {
        if (service === 'compoundV2') {
          positivePart += serviceData.protocolRatesInfo.supplyApy * position.ethValue
          positivePart += serviceData.protocolRatesInfo.compSupplyApy * position.ethValue
        } else if (service === 'aaveV2' || service === 'aaveV3') {
          positivePart += serviceData.protocolRatesInfo.depositApy * position.ethValue
        } else if (service === 'compoundV3_USDC') {
          positivePart += serviceData.protocolRatesInfo.supplyApy * position.ethValue
        } else {
          console.warn(`Unknown service ${service} during APY calculation`)
        }
      }

      totalSupply += position.ethValue
    } else if (position.type === 'borrow') {
      if (serviceData) {
        if (service === 'compoundV2') {
          negativePart += serviceData.protocolRatesInfo.borrowApy * position.ethValue
          negativePart -= serviceData.protocolRatesInfo.compBorrowApy * position.ethValue
        } else if (service === 'aaveV2' || service === 'aaveV3') {
          if (position.borrowType === 'stable') {
            negativePart += (position.userApy ?? serviceData.protocolRatesInfo.stableBorrowApy ?? 0) * position.ethValue
          } else if (position.borrowType === 'variable') {
            negativePart += (serviceData.protocolRatesInfo.variableBorrowApy ?? 0) * position.ethValue
          }
        } else if (service === 'compoundV3_USDC') {
          negativePart += serviceData.protocolRatesInfo.borrowApy * position.ethValue
        } else {
          console.warn(`Unknown service ${service} during APY calculation`)
        }
      }
    }
  }

  const netApyEth = positivePart - negativePart
  const netApy = (netApyEth / totalSupply) || 0 // Get rid of NaN from 0/0

  return { netApy, netApyEth }
}
