import { persistentWritable } from './persistentStore'
import { writableDerived, propertyStore } from 'svelte-writable-derived'
import services from '../lib/services'
import { getEmptyCoreData } from '../lib/liquidationCalculator'
import markets from '../stores/markets'
import { get } from 'svelte/store'
import { qclone } from 'qclone'
import { generateRandomString } from '../lib/utils'
import { importedWalletAddress } from './walletManager'

/*
  $calculatorState = { selectedService, services: {<service>: COREDATA}, servicesLinked, autoMarketRefresh, assetPrices: {<asset>: <price>}, quoteCoinPrices: {<symbol>: <ethPrice>} }
  COREDATA = { service, assets: { collateral, borrow }, stats, dirty, notificationsEnabled }
  $currentCoreData = $calculatorState.services[$calculatorState.selectedService]

  Note: stats are only useful with live data available! They are not updated otherwise
*/

export const thisTabId = generateRandomString(6)

const initialState = {
  selectedService: Object.keys(services)[0],
  services: Object.fromEntries(Object.keys(services).map(service => [service, getEmptyCoreData(service)])),
  servicesLinked: true,
  autoMarketRefresh: true,
  assetPrices: {},
  quoteCoinPrices: {},
  modifiedAssetPrices: {},
  updateStateCookie: null,
  activeTabId: null,
  initialized: false
}

export const activeTabId = persistentWritable('wehodlActiveTabId', null)

export const calculatorState = persistentWritable('wehodlCalculatorState', initialState)

export const roSavedCalculatorState = persistentWritable('wehodlRoSavedCalculatorState', null)

export default calculatorState

calculatorState.update(state => {
  if (state.selectedService === 'compound') state.selectedService = 'compoundV2'

  for (const [key, initialValue] of Object.entries(initialState)) {
    if (state[key] === undefined) state[key] = initialValue
  }

  for (const service of Object.keys(services)) {
    if (!state.services[service]) {
      state.services[service] = getEmptyCoreData(service)
    }
  }

  return state
})

export const selectedService = propertyStore(calculatorState, 'selectedService')
export const autoMarketRefresh = propertyStore(calculatorState, 'autoMarketRefresh')

const servicesCoreDataObject = propertyStore(calculatorState, 'services')
export const currentCoreData = writableDerived(
  [servicesCoreDataObject, selectedService],
  ([$servicesCoreDataObject, $selectedService]) => $servicesCoreDataObject[$selectedService],
  {
    withOld (newCoreData, [$servicesCoreDataObject, $selectedService]) {
      $servicesCoreDataObject[newCoreData.service] = newCoreData

      return [$servicesCoreDataObject, ...newCoreData.service !== $selectedService ? [newCoreData.service] : []]
    }
  }
)

export function updateStateFromMarkets () {
  const $markets = get(markets)

  calculatorState.update($calculatorState => {
    $calculatorState.quoteCoinPrices = {}
    for (const [symbol, { ethPrice }] of Object.entries($markets.quoteCoins)) {
      $calculatorState.quoteCoinPrices[symbol] = ethPrice
    }

    $calculatorState.assetPrices = {}
    for (const [symbol, { services }] of Object.entries($markets.coins)) {
      for (const [service, { price }] of Object.entries(services)) {
        if (!$calculatorState.assetPrices[service]) $calculatorState.assetPrices[service] = {}
        $calculatorState.assetPrices[service][symbol] = price
      }
    }

    $calculatorState.modifiedAssetPrices = {}

    return $calculatorState
  })
}

export function updateStateFromLiveData (liveData, updateStateCookie) {
  calculatorState.update($calculatorState => {
    $calculatorState.services = {}

    for (const service of Object.keys(services)) {
      if (!$calculatorState.services[service]) {
        $calculatorState.services[service] = {
          service,
          assets: {
            collateral: [],
            borrow: []
          },
          stats: liveData.services[service]?.stats ?? {},
          dirty: false,
          notificationsEnabled: liveData.services[service]?.notificationsEnabled ?? false
        }
      }

      $calculatorState.services[service].assets.collateral = [
        ...qclone(liveData.services[service]?.collateralAssets ?? []),
        ...qclone(liveData.services[service]?.unusedSupplyAssets ?? [])
      ]
      $calculatorState.services[service].assets.borrow = qclone(liveData.services[service]?.borrowAssets ?? [])
    }

    $calculatorState.autoMarketRefresh = true
    $calculatorState.servicesLinked = false
    if (updateStateCookie) $calculatorState.updateStateCookie = updateStateCookie

    return $calculatorState
  })

  updateStateFromMarkets()
}

export function clearRoState () {
  roSavedCalculatorState.set(null)
}

export function saveRoState () {
  const $calculatorState = get(calculatorState)

  const $importedWalletAddress = get(importedWalletAddress)

  if (($importedWalletAddress || 'null').toLowerCase() !== ($calculatorState.updateStateCookie || 'null').split(':')[0].toLowerCase()) return

  roSavedCalculatorState.set(qclone($calculatorState))
}

export function restoreRoState (currentUpdateStateCookie) {
  const $roSavedCalculatorState = get(roSavedCalculatorState)

  if (!$roSavedCalculatorState) return
  if ((currentUpdateStateCookie || 'null').split(':')[0].toLowerCase() !== ($roSavedCalculatorState.updateStateCookie || 'null').split(':')[0].toLowerCase()) return

  calculatorState.set({ ...qclone($roSavedCalculatorState), updateStateCookie: currentUpdateStateCookie?.startsWith('null:') ? null : currentUpdateStateCookie })
  roSavedCalculatorState.set(null)
}

export function switchService (service) {
  calculatorState.update($calculatorState => {
    if ($calculatorState.service === service) return $calculatorState

    const $markets = get(markets)
    const prevService = $calculatorState.selectedService
    $calculatorState.selectedService = service

    if ($calculatorState.servicesLinked) {
      const newAssets = qclone($calculatorState.services[prevService]?.assets ?? { collateral: [], borrow: [] })

      // Automatic conversion between ETH and WETH as needed
      let wethConverted = false
      for (const type of ['collateral', 'borrow']) {
        for (const asset of newAssets[type]) {
          if (asset.symbol === 'ETH' && !$markets.coins.ETH?.services[service] && $markets.coins.WETH?.services[service]) {
            asset.symbol = 'WETH'
            wethConverted = true
          } else if (asset.symbol === 'WETH' && !$markets.coins.WETH?.services[service] && $markets.coins.ETH?.services[service]) {
            asset.symbol = 'ETH'
            wethConverted = true
          }
        }

        newAssets[type] = newAssets[type]
          .filter(asset => $markets.coins[asset.symbol]?.services[service]?.[`${type}Active`])
          .concat($calculatorState.services[service].assets[type].filter(asset => !$markets.coins[asset.symbol]?.services[prevService]?.[`${type}Active`] && !(wethConverted && ['ETH', 'WETH'].includes(asset.symbol))))
      }

      $calculatorState.services[service].assets = newAssets
    }

    return $calculatorState
  })
}

export function setDirty (service) {
  calculatorState.update($calculatorState => {
    if ($calculatorState.services[service]) {
      $calculatorState.services[service].dirty = true
      delete $calculatorState.services[service].stats
    }

    return $calculatorState
  })
}
