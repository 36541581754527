<script>
  import { RouterLink } from '../router'
  import { Button, Icon } from 'svelma-fixed'
  import { router } from '@spaceavocado/svelte-router'
  import { createLoadingStore } from '../stores/loading'
  import jwt from 'jsonwebtoken'
  import { apiCall } from '../lib/api'

  export let token
  let email

  $: {
    try {
      email = jwt.decode(token).sub
    } catch (e) {
      console.error(e)
      email = null
    }
  }

  let unsubscribed = false
  const unsubscribing = createLoadingStore()

  async function unsubscribe () {
    await unsubscribing(async () => {
      await apiCall('POST', '/api/notifications/unsubscribe', { token })
      unsubscribed = true
    })
  }
</script>

<svelte:head>
	<title>Unsubscribe - WEHODL</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Unsubscribe
    </h4>

    {#if !email}
      <p class="has-text-danger">
        Invalid unsubscribe link.
      </p>

      <p>
        <RouterLink to={{ name: 'HOME' }}><Icon icon="home" /> Return to homepage</RouterLink>
      </p>
    {:else if unsubscribed}
      <p class="has-text-success">
        Your email {email} has been unsubscribed successfully.
      </p>

      <p>
        <RouterLink to={{ name: 'HOME' }}><Icon icon="home" /> Return to homepage</RouterLink>
      </p>
    {:else}
      <p>
        Are you sure you want to unsubscribe your email address {email} from all notifications?
      </p>

      <div class="buttons mt-3">
        <Button type="is-danger" loading={$unsubscribing} on:click={unsubscribe}>Unsubscribe</Button>
        <Button on:click={() => $router.push({ name: 'HOME' })}>Cancel</Button>
      </div>
    {/if}
  </div>
</div>
