<script>
  export let text = 'Loading...'
  export let inline = false

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma-fixed'
</script>

{#if inline}
  <div class="has-text-centered">
    <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
    <h2 class="subtitle">{text}</h2>
  </div>
{:else}
  <MiddleBox class="has-text-centered">
    <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
    <h2 class="subtitle">{text}</h2>
  </MiddleBox>
{/if}
