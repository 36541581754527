<script>
  import DropdownDialog from './DropdownDialog.svelte'

  export let actionId
  export let assetType
  export let assetEnabled
  export let isEnableStateForced = false

  $: items = {
    collateral: [
      { id: 'deposit', name: 'Deposit' },
      ...isEnableStateForced ? [] : [assetEnabled ? { id: 'disable', name: 'Disable' } : { id: 'enable', name: 'Enable' }],
      { id: 'withdraw', name: 'Withdraw' }
    ],
    borrow: [
      { id: 'borrow', name: 'Borrow' },
      { id: 'repay', name: 'Repay' }
    ]
  }[assetType] ?? []
</script>

<DropdownDialog value={actionId} {items} on:close />
