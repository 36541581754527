<script>
  import TxQueue from '../components/TxQueue.svelte'
  import { tick } from 'svelte'
  import { Input, Button } from 'svelma-fixed'

  let title = 'Test'
  let steps = [{
    id: 'step1',
    description: 'Step 1',
    status: 'success',
    hash: '0x1234567890'
  }, {
    id: 'step2',
    description: 'Step 2',
    status: 'skipped'
  }, {
    id: 'step3',
    description: 'Step 3',
    status: 'sending'
  }, {
    id: 'step4',
    description: 'Step 4',
    status: 'waiting'
  }]
  let queueActive = false
  let stepsInput = JSON.stringify(steps, null, 2)

  async function submit () {
    queueActive = false
    await tick()

    steps = JSON.parse(stepsInput)
    stepsInput = JSON.stringify(steps, null, 2)
    queueActive = true
  }
</script>

<svelte:head>
	<title>TX Manager Test - WEHODL</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      TX Manager Test Page
    </h4>
    <form on:submit|preventDefault={submit}>
      <Input type="text" bind:value={title} />
      <Input type="textarea" bind:value={stepsInput} rows={8} cols={40} />
      <Button nativeType="submit">Test</Button>
    </form>
  </div>
</div>

{#if queueActive}
  <TxQueue {title} {steps} auto={false} on:close={() => (queueActive = false)} />
{/if}
