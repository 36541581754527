import { ALL, getAllowance, getAmountForDisplay, getAmountInSmallestUnit, getAmountWithCorrectDecimals, getApproveStep, getDecimals, getErc20Balance, HALF_MAX_UINT256, MAX_UINT256, verifyMainnetChain, waitForTxHashOnly } from '.'
import { connectedWalletAddress, toChecksumAddress, currentProvider } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import * as ethers from 'ethers'
import { formatCurrency } from '../utils'
import Compound from '@compound-finance/compound-js'

export let compound
export { Compound }

let provider
currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
  compound = provider && new Compound(provider.getSigner())
})

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!compound) throw new Error('SDK unavailable')
}

// Fix problem that Compound.js was reading address from MetaMask in the wrong way, resulting in an "invalid name" error in some methods
if (compound) {
  Object.defineProperty(compound._provider, 'address', {
    get: function () {
      return this.provider?.provider?.selectedAddress
    }
  })
}

export async function compoundV2HasEnteredMarket (symbol) {
  await verifyPreconditions()

  const market = 'c' + symbol.replace(/^c/, '')
  const address = Compound.util.getAddress(market)
  if (!address) throw new Error(`Compound liquidity pool address not found for ${symbol}`)

  const enteredMarkets = await Compound.eth.read(Compound.util.getAddress(Compound.Comptroller), 'getAssetsIn', [get(connectedWalletAddress)], {
    _compoundProvider: compound._provider,
    abi: Compound.util.getAbi(Compound.Comptroller)
  })

  return enteredMarkets.map(m => m.toLowerCase()).includes(address.toLowerCase())
}

export async function compoundV2ActionEnableDisable (symbol, enabled) {
  await verifyPreconditions()

  return [{
    id: enabled ? 'enable' : 'disable',
    description: enabled ? 'Enter lending market' : 'Exit lending market',
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (await compoundV2HasEnteredMarket(symbol) === enabled) {
        return null
      } else {
        if (!Compound[symbol]) throw new Error(`Coin ${symbol} not found in Compound SDK`)
        const hash = await waitForTxHashOnly(enabled ? compound.enterMarkets(Compound[symbol]) : compound.exitMarket(Compound[symbol]), setWaitingForUser)

        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'compoundV2',
            type: enabled ? 'enable' : 'disable',
            symbol
          },
          icon: 'power-off',
          description: `${enabled ? 'Enable' : 'Disable'} ${symbol} for use in Compound V2`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    }
  }]
}

export async function compoundV2ActionDeposit (symbol, amount, enable) {
  await verifyPreconditions()

  const steps = []

  if (amount === ALL) {
    if (symbol === 'ETH') throw new Error('ALL is not supported for ETH')

    amount = await getErc20Balance(symbol)
  }
  const mantissa = typeof amount !== 'number'

  if (Number(amount) > 0) {
    const cTokenAddress = Compound.util.getAddress('c' + symbol)
    if (!cTokenAddress) throw new Error(`Compound token address not found for ${symbol}`)

    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, cTokenAddress)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(getApproveStep(symbol, cTokenAddress, 'compoundV2'))
    }

    steps.push({
      id: 'deposit',
      description: `Supply ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        if (!Compound[symbol]) throw new Error(`Coin ${symbol} not found in Compound SDK`)
        const hash = await waitForTxHashOnly(compound.supply(Compound[symbol], await getAmountWithCorrectDecimals(symbol, amount), true, { mantissa }), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'compoundV2',
            type: 'deposit',
            symbol,
            amount: await getAmountForDisplay(symbol, amount)
          },
          icon: 'sign-in-alt',
          description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Compound V2`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })
  }

  if (enable != null && await compoundV2HasEnteredMarket(symbol) !== enable) {
    steps.push(...await compoundV2ActionEnableDisable(symbol, enable))
  }

  return steps
}

export async function compoundV2ActionWithdraw (symbol, amount) {
  await verifyPreconditions()

  let redeemSymbol = symbol
  let redeemAmount = amount

  if (amount === ALL) {
    redeemSymbol = 'c' + symbol

    const cTokenAddress = Compound.util.getAddress(redeemSymbol)
    if (!cTokenAddress) throw new Error(`Compound token address not found for ${symbol}`)

    const options = {
      _compoundProvider: compound._provider,
      abi: Compound.util.getAbi(symbol === 'ETH' ? 'cEther' : 'cErc20')
    }

    const cTokenBalance = await Compound.eth.read(cTokenAddress, 'balanceOf', [get(connectedWalletAddress)], options)
    redeemAmount = cTokenBalance

    if (!Number(cTokenBalance)) return []

    const exchangeRate = await Compound.eth.read(cTokenAddress, 'exchangeRateCurrent', [], options)

    // For display purposes only
    amount = cTokenBalance.mul(exchangeRate).toString() / 10 ** (18 + await getDecimals(symbol))
  }
  const mantissa = typeof redeemAmount !== 'number'

  return [{
    id: 'withdraw',
    description: `Redeem ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (!Compound[redeemSymbol]) throw new Error(`Coin ${redeemSymbol} not found in Compound SDK`)
      const hash = await waitForTxHashOnly(compound.redeem(Compound[redeemSymbol], mantissa ? redeemAmount : await getAmountWithCorrectDecimals(symbol, redeemAmount), { mantissa }), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'compoundV2',
          type: 'withdraw',
          symbol,
          amount: await getAmountForDisplay(symbol, amount)
        },
        icon: 'sign-out-alt',
        description: `Withdraw ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} from Compound V2`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  }]
}

export async function compoundV2ActionBorrow (symbol, amount) {
  await verifyPreconditions()

  if (amount === ALL) throw new Error('ALL is not supported for borrow')
  const mantissa = typeof amount !== 'number'

  return [{
    id: 'borrow',
    description: `Borrow ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (!Compound[symbol]) throw new Error(`Coin ${symbol} not found in Compound SDK`)
      const hash = await waitForTxHashOnly(compound.borrow(Compound[symbol], await getAmountWithCorrectDecimals(symbol, amount), { mantissa }), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'compoundV2',
          type: 'borrow',
          symbol,
          amount: await getAmountForDisplay(symbol, amount)
        },
        icon: 'sign-out-alt',
        description: `Borrow ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} from Compound V2`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  }]
}

export async function compoundV2ActionRepay (symbol, amount) {
  await verifyPreconditions()

  const steps = []

  const mantissa = typeof amount !== 'number'

  const cTokenAddress = Compound.util.getAddress('c' + symbol)
  if (!cTokenAddress) throw new Error(`Compound token address not found for ${symbol}`)

  // We are comparing with half the maxiumum allowance here in case there are contracts that do not handle -1 separately and have already dedcuted a bit.
  const amountInSmallestUnit = amount === ALL ? HALF_MAX_UINT256 : await getAmountInSmallestUnit(symbol, amount)
  if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, cTokenAddress)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
    steps.push(getApproveStep(symbol, cTokenAddress, 'compoundV2'))
  }

  steps.push({
    id: 'repay',
    description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (!Compound[symbol]) throw new Error(`Coin ${symbol} not found in Compound SDK`)
      const hash = await waitForTxHashOnly(compound.repayBorrow(Compound[symbol], amount === ALL ? MAX_UINT256 : await getAmountWithCorrectDecimals(symbol, amount), null, true, { mantissa }), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'compoundV2',
          type: 'repay',
          symbol,
          ...amount === ALL ? { all: true } : { amount: await getAmountForDisplay(symbol, amount) }
        },
        icon: 'sign-in-alt',
        description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Compound V2`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}
