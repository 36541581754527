<script>
  import { Button, Dialog, Field, Icon, Input, Switch } from 'svelma-fixed'
  import { autoFocusInput, delay, formatCurrency, formatPercentage, onlyCustom, parseCurrency } from '../lib/utils'
  import { quoteCoin } from '../stores/quoteCoin'
  import { tick } from 'svelte'
  import RangeSlider from 'svelte-range-slider-pips'
  import { getCircleColors } from '../../shared/lib/fullStateCalculator'
  import Tooltip from '../components/Tooltip.svelte'
  import { connectBlocknative, connectedWallet, updateLiveData, updatingLiveData, walletConnecting } from '../stores/walletManager'
  import services from '../lib/services'
  import { fly, slide } from 'svelte/transition'
  import { createLoadingStore } from '../stores/loading'
  import { getLoanComparisonResults, augmentLoanComparisonResults } from '../lib/compare'
  import markets from '../stores/markets'
  import { flip } from 'svelte/animate'
  import Debouncer from '../lib/Debouncer'
  import InfoTooltip from '../components/InfoTooltip.svelte'
  import CoinMultiSelect from '../components/CoinMultiSelect.svelte'
  import ComparisonDetailsGasEstimation from '../components/ComparisonDetailsGasEstimation.svelte'
  import TxQueue from '../components/TxQueue.svelte'
  import { devOptions } from '../stores/devOptions'
  import SingleSelect from '../components/SingleSelect.svelte'

  let borrowAmount = 0
  let safetyMargin = 0.45

  let addToExisting = false
  let dummyAddToExisting = false

  const executing = createLoadingStore()

  const filters = {
    tokensToBorrow: ['DAI', 'TUSD', 'USDC', 'USDP', 'USDT'],
    tokensToDeposit: ['WBTC', 'ETH'],
    services: '',
    sortBy: 'apy'
  }

  $: numberOfFilters = Object.entries(filters).filter(([k, v]) => v.length && k !== 'sortBy').length

  const editValues = {
    borrowAmount: undefined,
    safetyMargin: undefined
  }

  $: if (!$connectedWallet && addToExisting) addToExisting = false

  formatEditValue('safetyMargin', safetyMargin, true)

  function parseEditValue (key, value = editValues[key]) {
    return ({
      borrowAmount: () => Math.max(0, parseCurrency(value, $quoteCoin.symbol)),
      safetyMargin: () => value[0] / 100
    })[key]()
  }

  function reformatEditValue (key) {
    formatEditValue(key, parseEditValue(key), true)
  }

  function formatEditValue (key, value, force = false) {
    const formatted = ({
      borrowAmount: () => formatCurrency(value, $quoteCoin.symbol),
      safetyMargin: () => [(value * 100) || 0]
    })[key]()

    if (force || editValues[key] === undefined || parseEditValue(key, formatted) !== parseEditValue(key)) {
      editValues[key] = formatted
      tick().then(() => { editValues[key] = formatted }) // Weird bug: Input value doesn't update if it's not set twice in some conditions? (Test with changing units...)
    }
  }

  function onBorrowAmountChange (force = false) {
    if (!force) borrowAmount = parseEditValue('borrowAmount')
  }

  function onSliderChange () {
    safetyMargin = parseEditValue('safetyMargin')
  }

  function resetFilters () {
    filters.tokensToBorrow = []
    filters.tokensToDeposit = []
    filters.services = ''
  }

  let results = null
  let augmentedResults = null
  const loading = createLoadingStore()

  const walletConnectingThroughSwitch = createLoadingStore()

  const ANIM_TIME = 200

  const debouncer = new Debouncer(ANIM_TIME + 50, true)

  $: if (borrowAmount > 0 && safetyMargin > 0 && safetyMargin < 1 && ($connectedWallet, $markets, $quoteCoin, true)) { // $connectedWallet, $markets and $quoteCoin exist only for reactivity
    debouncer.debounce(() => {
      if (borrowAmount > 0 && safetyMargin > 0 && safetyMargin < 1) {
        // Note: This does not behave well if we reintroduce changing quote currency
        const hadDetailsOpen = new Set(augmentedResults?.filter(r => r._detailsOpen).map(r => r._key))
        results = getLoanComparisonResults(filters.tokensToBorrow, borrowAmount / $quoteCoin.ethPrice, safetyMargin)
        augmentedResults = augmentLoanComparisonResults(results, addToExisting, filters, hadDetailsOpen)
      } else {
        results = null
      }
    })
  } else {
    results = null
  }

  $: coinItems = Object.values($markets.coins).filter(coin => !coin.hidden && coin.symbol !== 'WETH').map(coin => {
    return {
      ...coin,
      dispSymbol: coin.symbol === 'ETH' ? '(W)ETH' : coin.symbol,
      dispName: coin.symbol === 'ETH' ? '(Wrapped) Ether' : coin.name
    }
  })

  let filterBoxOpen = false
  $: if (!results && filterBoxOpen) filterBoxOpen = false

  $: noLoanFound = $connectedWallet?.liveData && !Object.values($connectedWallet.liveData.services).some(s => s.collateralAssets.length)

  async function onSwitchClickWhileNoWallet () {
    await walletConnectingThroughSwitch(async () => {
      dummyAddToExisting = true
      await connectBlocknative()
      dummyAddToExisting = false

      if ($connectedWallet) {
        addToExisting = true

        if ($updatingLiveData) {
          await new Promise(resolve => {
            const unsub = $updatingLiveData.subscribe(() => {
              if (!$updatingLiveData) {
                unsub()
                resolve()
              }
            })
          })
        }

        await delay(250) // For some reason we always got a temporary info about insufficient funds
      }
    })
  }

  let txQueueData = null // { title, steps }

  async function execute (loanPlan) {
    if (loanPlan.service.actionsNotYetSupported) {
      Dialog.alert({
        message: 'Sorry, this DeFi service is not yet supported for actions directly within WEHODL!',
        type: 'is-warning',
        icon: 'exclamation-triangle'
      })
      return
    }

    await executing(async () => {
      const steps = await loanPlan.getSteps()
      txQueueData = { title: `Borrow ${loanPlan.borrow.dispSymbol} against ${loanPlan.collateral.dispSymbol ?? 'existing collateral'} on ${loanPlan.service.name}`, steps }
    })
  }

  // Without this, we get an errornous animation from the animate:flip directive whenever the details pane is opened
  let fixPageJump = false
  async function doFixPageJump () {
    fixPageJump = true
    await tick()
    fixPageJump = false
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .title {
    font-weight: normal;
  }

  .has-line-through {
    text-decoration: line-through;
  }

  .options-box {
    :global(.field) {
      position: relative;
    }

    :global(.field.borrowAmount input) {
      text-align: center;
      font-size: 175%;
      font-weight: 500;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      height: auto;
      max-width: 400px;
    }

    :global(.field input) {
      &:active, &:focus {
        // Reduce size of box-shadow a bit
        box-shadow: rgba(102, 151, 229, 0.25) 0px 0px 0px 2px;
      }
    }
  }

  .slider-container {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: grid;
      grid-template-areas:
        "min max"
        "slider slider";

      height: 4em;
      padding-bottom: 0.5em;

      span:first-child {
        grid-area: min;
        justify-self: start;
      }

      span:last-child {
        grid-area: max;
        justify-self: end;
      }

      :global(.rangeSlider) {
        grid-area: slider;

        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    :global(.rangeSlider) {
      flex: 1;
      margin-left: 2.5em;
      margin-right: 2.5em;

      cursor: pointer;

      --range-handle-inactive: white;
      --range-handle-focus: white;

      // Nifty trick, we can make use of the aria-valuetext attribute that gets set by the range slider for a11y in order
      // to make the handle display the slider value, without changing the slider component!
      :global(.rangeHandle) {
        width: 4em;
        height: 2em;

        &::after {
          content: attr(aria-valuetext);
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          text-align: center;
        }

        :global(.rangeNub) {
          border: 3px solid var(--slider-color);
          transition: border-color 0.2s;
        }
      }
    }

    :global(.rangeSlider.disabled) {
      cursor: not-allowed;
    }
  }

  .filter-button {
    :global(.button) {
      border: 1px solid $border;
      padding: 0.25em 0.5em;
    }

    .tag {
      border: 1px solid $border;
      padding: 0.25em 0.45em;
      line-height: 1;
      height: auto;
      margin-right: 0.5em;
    }

    .fa {
      font-size: 1rem;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
  }

  .table-wrapper {
    display: grid;

    // Show on top of each other
    > * {
      grid-area: 1 / 1 / 1 / 1;
      height: fit-content;
    }

    .filter-box {
      z-index: 1;
      align-self: start;
      justify-self: end;
      position: relative;
      top: -6px;

      display: grid;
      gap: 1.5em;
      grid-template-columns: 130px 330px;
      align-items: center;

      .level {
        grid-column: span 2;
        margin-bottom: 0.25em;
        width: 100%;
      }

      > :global(*:nth-child(odd)) {
        justify-self: start;
      }

      > :global(*:nth-child(even)) {
        justify-self: end;
      }

      @include mobile {
        grid-template-columns: 1fr;

        .level {
          margin-bottom: 1em;
          grid-column: auto;
        }
      }
    }
  }

  .table {
    display: block;
    margin-bottom: 2em;

    border-radius: $radius;
    border: 1px solid $border;

    thead, tbody {
      display: contents;
    }

    thead tr {
      border-bottom: 1px solid $border;

      th {
        color: $grey;
        font-size: 0.8em;
        font-weight: normal;
      }
    }

    tbody tr:not(.no-results):not(.details) {
      &.clickable {
        cursor: pointer;
      }

      &:hover {
        background-color: $table-select-bg;
      }
    }

    tr:not(.details) {
      padding: 10px 15px;
      display: grid;

      align-items: center;

      grid-template-columns: 40px 0.5fr 1fr 1fr 0.5fr 40px;
      grid-template-areas:
        "index service collateral borrow apy arrow";

      @include mobile {
        grid-template-columns: 40px 1fr 40px;
        grid-template-areas:
          "index service arrow"
          "_ collateral collateral"
          "_ borrow borrow"
          "_ apy apy";
      }

      &.no-results {
        grid-template-columns: 1fr;
        grid-template-areas: "no-results";

        td {
          grid-area: no-results !important;
        }
      }

      th, td {
        display: block;
        border: none;
        line-height: 1;

        &:nth-child(1) {
          grid-area: index;
        }

        &:nth-child(2) {
          grid-area: service;
        }

        &:nth-child(3) {
          grid-area: collateral;
        }

        &:nth-child(4) {
          grid-area: borrow;
        }

        &:nth-child(5) {
          grid-area: apy;
        }

        &:nth-child(6) {
          grid-area: arrow;

          .chevron {
            transition: transform 0.2s;
            display: inline-block;
            font-size: 1.5em;

            &.rotated {
              transform: rotate(180deg);
            }
          }
        }
      }

      td:first-child {
        color: $grey;
        font-size: 0.8em;
      }

      td {
        span {
          font-weight: 500;

          small {
            color: currentColor;
            font-weight: normal;
            font-size: 0.7em;
            vertical-align: top;
            position: relative;
          }
        }

        small {
          color: $grey;
          font-size: 0.8em;
        }
      }
    }

    tr.details {
      padding: 10px 22px;
      display: block;

      td {
        display: block;

        border-bottom: 1px solid $border;
        padding: 0 45px 15px;

        .details-row {
          display: grid;

          grid-template-columns: 1.05fr 0.85fr 0.3fr 0.85fr; // This is trying to match the alignment of the collateral column
          grid-template-areas: "label old arrow new";

          font-size: 0.9em;
          min-height: 2.25em;
          align-items: center;

          @include mobile {
            grid-template-columns: 1fr 32px 1fr;
            grid-template-areas:
              "label label label"
              "old arrow new";
          }

          > :nth-child(1) {
            grid-area: label;
          }

          > :nth-child(2) {
            grid-area: old;
            text-align: left;

            &:last-child {
              grid-area: old / old / new / new;
              text-align: right;
            }
          }

          > :nth-child(3) {
            grid-area: arrow;
            text-align: center;

            font-size: 1.5em;
          }

          > :nth-child(4) {
            grid-area: new;
            text-align: right;
          }
        }

        .big-action-button {
          padding: 6px;
          width: 100%;

          :global(button) {
            font-size: 1.25em;
            width: 100%;
          }
        }
      }
    }

    .image .tag {
      position: absolute;
      top: -4px;
      right: -10px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1;
      height: auto;
      font-size: 60%;
      font-weight: 500;
      border: 2px solid $border;
    }

    .image .has-loan-indicator {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 16px;
      height: 16px;
      border: 2px solid white;
      border-radius: 50%;
      background-color: #0cc144;
    }

    .media {
      align-items: center;
    }

    .media-content {
      line-height: 1.15;
    }
  }
</style>

<svelte:head>
	<title>Compare Loans - WEHODL</title>
</svelte:head>

<svelte:body on:click={e => { if (!e.target.closest('.filter-box, .filter-button')) filterBoxOpen = false }} />

<div class="container">
  <h4 class="title is-4 my-3">
    Compare DeFi Loan Offers
  </h4>

  <form class="mb-5" novalidate>
    <fieldset disabled={$loading}>
      <div class="box options-box py-2" use:autoFocusInput>
        <div class="columns">
          <Field label="Borrow:" class="column is-4 borrowAmount">
            <Input placeholder="{formatCurrency(0, $quoteCoin.symbol)}" bind:value={editValues.borrowAmount} on:input={onlyCustom(() => onBorrowAmountChange())}  on:change={() => reformatEditValue('borrowAmount')} inputmode="decimal" pattern="[0-9.]\d" novalidate />
          </Field>
          <span style:display="contents" class="info-tooltip-area">
            <Field class="column is-8" addons={false}>
              <label class="label" for="safety-margin-slider">
                With safety margin of:
                <InfoTooltip>
                  <p>Safety Margin is a crucial parameter that measures how far your Collateral Balance is from Liquidation.</p>
                  <p><a href="https://blog.wehodl.finance/safety-margin-the-key-to-avoiding-liquidation-when-borrowing-crypto-eb891c2093e4" target="_blank" rel="noopener noreferrer">Learn more</a></p>
                </InfoTooltip>
              </label>
              <div class="slider-container" id="safety-margin-slider" style:--slider-color={getCircleColors(safetyMargin).circleOuterColor}>
                <span>Liquidation</span>
                <RangeSlider id="safety-margin-slider" bind:values={editValues.safetyMargin} min={1} max={99} suffix="%" disabled={$loading} on:change={onSliderChange} />
                <span>Safe</span>
              </div>
            </Field>
          </span>
        </div>
      </div>
    </fieldset>
  </form>

  {#if results}
    <div transition:slide|local>
      <div class="level mb-3">
        <div class="level-left">
          <div class="level-item">
            {#if $connectedWallet && !dummyAddToExisting}
              <Switch bind:checked={addToExisting}>Add to existing loans in my wallet.</Switch>
            {:else}
              <Switch bind:checked={dummyAddToExisting} disabled={$walletConnecting || $updatingLiveData} on:input={onSwitchClickWhileNoWallet}>Add to existing loans in my wallet.</Switch>
            {/if}
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <span class="filter-button">
              <Tooltip label="Filters" position="is-left">
                <Button outlined size="is-small" on:click={() => (filterBoxOpen = !filterBoxOpen)}><span class="is-hidden-tablet">Filters </span><div class="tag is-white">{numberOfFilters}</div> <i class="fa fa-sliders"></i></Button>
              </Tooltip>
            </span>
          </div>
        </div>
      </div>

      {#if noLoanFound && addToExisting}
        <div class="notification is-warning mb-3" transition:slide|local>
          <Icon icon="info-circle" /> You currently have no loan or supply on any of the supported DeFi services, so there is nothing to add to.
        </div>
      {/if}

      <div class="table-wrapper">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>DeFi</th>
              <th>Collateral</th>
              <th>Borrow</th>
              <th class="info-tooltip-area">
                Est. Annual Yield
                <InfoTooltip>
                  <p>Estimated Annual Yield is the annualized interest that you will earn on your deposit or pay on your loan.</p>
                  <p>This value is calculated only for the new loan/deposit needed, not for any existing loan.</p>
                </InfoTooltip>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {#each augmentedResults as result, i (result._key)}
              {@const details = result._detailsOpen && result.getDetails()}
              <div animate:flip={{ duration: fixPageJump ? 0 : ANIM_TIME }}> <!-- Normally, a div inside a tr tbody wouldn't work as expected, but here it works because the table doesn't have display: table. -->
                <tr transition:fly|local={{ duration: ANIM_TIME * 4 / 7, y: -10 }} class="clickable" class:has-text-grey={result.hasDisabledCollateral} on:click={() => { doFixPageJump(); result._detailsOpen = !result._detailsOpen }}>
                  <td>{i + 1}</td>
                  <td>
                    <Tooltip label="{result.service.name}" position="is-right">
                      <figure class="image is-32x32">
                        <img src="{result.service.icon}" alt="{result.service.name}" />
                        {#if result.service.badge}
                          <span class="tag is-light">{result.service.badge}</span>
                        {/if}
                        {#if result.borrow.isChange}
                          <span class="has-loan-indicator"></span>
                        {/if}
                      </figure>
                    </Tooltip>
                  </td>
                  <td>
                    <div class="media">
                      <div class="media-left">
                        <figure class="image is-32x32">
                          <img src="{result.collateral.iconUrl}" alt="{result.collateral.dispSymbol ?? '(none)'}" />
                        </figure>
                      </div>
                      <div class="media-content">
                        {#if result.collateral.isChange && !result.collateral.units}
                          <span>No deposit needed</span><br />
                          <small>+{formatCurrency(0, $quoteCoin.symbol, undefined, undefined, true)}</small>
                        {:else}
                          <span class:has-line-through={result.hasDisabledCollateral}>
                            {result.collateral.isChange && result.collateral.units > 0 ? '+' : ''}{formatCurrency(result.collateral.units, undefined, -6, '-', true)} {result.collateral.dispSymbol}
                          </span><br />
                          <small>
                            {#if result.hasDisabledCollateral}
                              <i class="fa fa-power-off"></i> Has existing disabled collateral
                            {:else}
                              {result.collateral.isChange && result.collateral.units > 0 ? '+' : ''}{formatCurrency(result.collateral.quoteValue, $quoteCoin.symbol, undefined, undefined, true)}
                            {/if}
                          </small>
                        {/if}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="media">
                      <div class="media-left">
                        <figure class="image is-32x32">
                          <img src="{result.borrow.iconUrl}" alt="{result.borrow.dispSymbol}" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <span>
                          {result.borrow.isChange && result.borrow.units > 0 ? '+' : ''}{formatCurrency(result.borrow.units, undefined, -6, '-', true)} {result.borrow.dispSymbol}
                        </span><br />
                        <small>{result.borrow.isChange && result.borrow.units > 0 ? '+' : ''}{formatCurrency(result.borrow.quoteValue, $quoteCoin.symbol, undefined, undefined, true)}</small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="media">
                      <div class="media-content">
                        <span>
                          {#if $devOptions.debugOptions.comparisonMultiApy}
                            {formatPercentage(result.apy.ethValue / result.collateral.ethValue, 2, 2, 'N/A')} | {formatPercentage(result.apy.ethValue / (result.collateral.ethValue - result.borrow.ethValue), 2, 2, 'N/A')} | {formatPercentage(result.apy.value, 2, 2, 'N/A')}
                          {:else}
                            {result.apy.ethValue > 0 ? '+' : ''}{formatCurrency(result.apy.quoteValue, $quoteCoin.symbol, undefined, undefined, true)}/yr
                          {/if}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="chevron" class:rotated={result._detailsOpen}>
                      <i class="fa fa-chevron-down"></i>
                    </span>
                  </td>
                </tr>

                {#if details}
                  <tr transition:slide|local class="details">
                    <td colspan="6">
                      {#if result.hasDisabledCollateral}
                        <div class="notification is-warning" transition:slide|local>
                          <Icon icon="info-circle" /> You have existing {result.collateral.dispSymbol} supply on {result.service.name} but it is <strong>disabled</strong> and thus not used as collateral. Proper calculation of loan options with {result.collateral.dispSymbol} collateral on {result.service.name} with your wallet is not possible unless your {result.collateral.dispSymbol} is enabled as collateral first!
                        </div>
                      {:else}
                        <div class="columns">
                          <div class="column is-half">
                            <div class="details-row">
                              <span>Safety Margin</span>
                              {#if details.oldState}
                                <span style:color={details.oldState.calcValues.circleInnerColor}>{formatPercentage(details.oldState.calcValues.marketDropRatio, 2, 2, '-')}</span>
                                <span>&rarr;</span>
                              {/if}
                              <span style:color={details.newState.calcValues.circleInnerColor}>{formatPercentage(details.newState.calcValues.marketDropRatio, 2, 2, '-')}</span>
                            </div>
                            <div class="details-row">
                              <span>Collateral</span>
                              {#if details.oldState}
                                <span>{formatCurrency(details.oldState.calcValues.collateralValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                                <span>&rarr;</span>
                              {/if}
                              <span>{formatCurrency(details.newState.calcValues.collateralValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                            </div>
                            <div class="details-row">
                              <span>Borrow</span>
                              {#if details.oldState}
                                <span>{formatCurrency(details.oldState.calcValues.borrowValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                                <span>&rarr;</span>
                              {/if}
                              <span>{formatCurrency(details.newState.calcValues.borrowValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                            </div>
                          </div>

                          <div class="column is-half">
                            <div class="details-row">
                              <span>Liquidation Threshold</span>
                              {#if details.oldState}
                                <span>{formatCurrency(details.oldState.calcValues.thresholdCollateralValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                                <span>&rarr;</span>
                              {/if}
                              <span>{formatCurrency(details.newState.calcValues.thresholdCollateralValue * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                            </div>
                            <div class="details-row">
                              <span>Borrow Limit</span>
                              {#if details.oldState}
                                <span>{formatCurrency(details.oldState.calcValues.borrowLimit * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                                <span>&rarr;</span>
                              {/if}
                              <span>{formatCurrency(details.newState.calcValues.borrowLimit * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</span>
                            </div>
                            <div class="details-row">
                              <span>Network Cost</span>
                              <span><ComparisonDetailsGasEstimation loanPlan={result} /></span>
                            </div>
                          </div>
                        </div>

                        {#if addToExisting && !$walletConnectingThroughSwitch}
                          {#if result.hasSufficientBalance}
                            <div class="big-action-button" transition:slide|local>
                              <Button type="is-primary" loading={$updatingLiveData || $executing || txQueueData} on:click={execute(result)}>Borrow</Button>
                            </div>
                          {:else}
                            <div class="notification is-warning mb-3" transition:slide|local>
                              <Icon icon="info-circle" /> Your {result.collateral.dispSymbol} balance is insufficient to execute this loan. Please deposit more {result.collateral.dispSymbol} to your wallet.
                            </div>
                          {/if}
                        {:else}
                          <div class="notification is-info mb-3" transition:slide|local>
                            {#if $walletConnecting || $walletConnectingThroughSwitch}
                              <Icon icon="spinner" customClass="fa-pulse" /> Connecting wallet...
                            {:else if $connectedWallet}
                              <Icon icon="info-circle" /> You need to <a href={undefined} on:click={() => (addToExisting = true)}>enable "Add to existing loans in my wallet"</a> to execute this loan.
                            {:else}
                              <Icon icon="info-circle" /> You need to <a href={undefined} on:click={onSwitchClickWhileNoWallet}>connect your wallet</a> to execute this loan.
                            {/if}
                          </div>
                        {/if}
                      {/if}
                    </td>
                  </tr>
                {/if}
              </div>
            {:else}
              <tr class="no-results">
                <td colspan="6" class="has-text-centered">No results. Check your filters!</td>
              </tr>
            {/each}
          </tbody>
        </table>

        {#if filterBoxOpen}
          <div class="box filter-box" transition:fly|local={{ duration: 300, y: 20 }}>
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item"><strong>Filters</strong></div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <Tooltip position="is-left" label="Clear filters">
                    <Button size="is-small" outlined on:click={resetFilters} disabled={numberOfFilters === 0}><Icon icon="trash-alt" /></Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <hr class="is-hidden" />

            <p>Borrow tokens</p>
            <CoinMultiSelect bind:selected={filters.tokensToBorrow} coinItems={coinItems.map(c => ({ ...c, disabledReason: filters.tokensToDeposit.includes(c.symbol) ? 'Already used in collateral asset filter' : null }))} maxSelect={5} required />

            <p>Collateral tokens</p>
            <CoinMultiSelect bind:selected={filters.tokensToDeposit} coinItems={coinItems.map(c => ({ ...c, disabledReason: filters.tokensToBorrow.includes(c.symbol) ? 'Already used in borrow asset filter' : null }))} placeholder="All" />

            <p>DeFi protocols</p>
            <SingleSelect bind:selected={filters.services} options={[
              { value: '', label: 'All' },
              ...Object.values(services).map(service => ({ value: service.id, label: service.name }))
            ]} />

            <p>Sort by</p>
            <SingleSelect bind:selected={filters.sortBy} options={[
              { value: 'service', label: 'DeFi Service' },
              { value: 'collateralAmount', label: 'Collateral Value' },
              { value: 'collateral', label: 'Collateral Asset' },
              { value: 'borrow', label: 'Borrow Asset' },
              { value: 'apy', label: 'Est. Annual Yield' }
            ]} />
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

{#if txQueueData}
  <TxQueue title={txQueueData.title} steps={txQueueData.steps} on:close={() => (txQueueData = null)} on:done={() => updateLiveData()} />
{/if}
