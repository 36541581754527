<script>
  import MultiSelect from 'svelte-multiselect' // https://multiselect.janosh.dev/
  import { escapeRegex } from '../lib/utils'
  import { tick } from 'svelte'

  export let disabled = false
  export let loading = false
  export let selected = []
  export let maxSelect = null
  export let coinItems = []
  export let placeholder
  export let required = false

  $: options = coinItems.map(coin => {
    /*
      export type ObjectOption = {
        label: string | number // user-displayed text
        value?: unknown // associated value, can be anything incl. objects (defaults to label if undefined)
        title?: string // on-hover tooltip
        disabled?: boolean // make this option unselectable
        preselected?: boolean // make this option selected on page load (before any user interaction)
        disabledTitle?: string // override the default disabledTitle = 'This option is disabled'
        selectedTitle?: string // tooltip to display when this option is selected and hovered
        style?: OptionStyle
        [key: string]: unknown // allow any other keys users might want
      }
    */

    const item = {
      label: coin.dispName ?? coin.name,
      value: coin.symbol,

      // Nonstandard, only for dropdown
      disabled: !!coin.disabledReason,
      disabledTitle: coin.disabledReason,

      custom: {
        // dispSymbol is not universal but we need it for the compare filters where we collapse WETH and ETH independently of the services
        dispSymbol: coin.dispSymbol ?? coin.symbol,
        dispName: coin.dispName ?? coin.name,
        coin
      }
    }

    return item
  })

  let rawSelected
  let lastKnownSelected = null
  $: if (lastKnownSelected !== selected.join(',')) {
    lastKnownSelected = selected.join(',')
    rawSelected = selected.map(symbol => options.find(option => option.value === symbol)).filter(x => x)
  }

  $: empty = !selected.length

  function onChange (event) {
    selected = (event?.detail?.type === 'removeAll' ? [] : rawSelected).map(option => option.value)
    lastKnownSelected = selected.join(',')
  }

  // This trickery is done to prevent accidentally removing items when clicking on the dropdown because the remove buttons fire on mouseup
  let preventClicks = false
  let open

  function onMouseDown () {
    if (!open) preventClicks = true
  }

  // 1. This is better global because the flag should be reset even when mouse is released elsewhere
  // 2. on:mouseup doesn't even work on the multiselect even though on:mousedown does!
  function onGlobalMouseUp () {
    tick().then(() => {
      preventClicks = false
    })
  }

  function closeOnArrowClick (node) {
    const handler = event => {
      if (open) {
        open = false
        tick().then(() => { node.querySelector('input[ondrop]')?.blur() })
      }
    }
    node.querySelector('svg')?.addEventListener('click', handler)
    return {
      destroy () {
        node.querySelector('svg')?.removeEventListener('click', handler)
      }
    }
  }

  /*
    export type ObjectOption = {
      label: string | number // user-displayed text
      value?: unknown // associated value, can be anything incl. objects (defaults to label if undefined)
      title?: string // on-hover tooltip
      disabled?: boolean // make this option unselectable
      preselected?: boolean // make this option selected on page load (before any user interaction)
      disabledTitle?: string // override the default disabledTitle = 'This option is disabled'
      selectedTitle?: string // tooltip to display when this option is selected and hovered
      style?: OptionStyle
      [key: string]: unknown // allow any other keys users might want
    }
  */
</script>

<svelte:window on:mouseup|capture={onGlobalMouseUp} />

<style lang="scss">
  .coinIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 8px;
  }

  main {
    display: contents;

    --sms-border-radius: #{$radius};
    --sms-border: 1px solid #{$border};
    --sms-remove-btn-hover-color: white;
    --sms-text-color: rgb(54, 54, 54); // Default control text color
    --sms-padding: 0 0.125em;

    &.required.empty {
      --sms-border: 1px solid #{$danger};
    }

    // Make the arrow behave
    :global(.multiselect::after) {
      transition: transform 0.2s;
    }

    :global(.multiselect.open::after) {
      transform: rotate(135deg) translate(3px, -3px);
    }

    // This moves the double arrow to the right, makes it wider and actually invisible because the `select` class will allow Bulma to show the real arrow instead
    :global(.multiselect > svg) {
      order: 1;
      visibility: hidden;
      width: 32px;
    }

    :global(.multiselect > svg > *) {
      display: none;
    }

    // Necessary to make it clickable when the dropdown is open
    :global(.multiselect.open > svg) {
      cursor: pointer;
      visibility: visible;
    }

    :global(.multiselect:hover) {
      border-color: #b5b5b5; // Default hover color for input box
    }

    // Hack to temporarily make the max-select-msg red when it gets wiggled
    :global(.multiselect > span) {
      transition: background-color 0.2s, color 0.2s;
      border-radius: $radius-small;
    }

    :global(.multiselect > span:not([style*="rotate(0deg)"]):has(.max-select-msg)) {
      background: $danger;
      color: white;
    }

    :global(.multiselect:not(.open):not(.disabled)) {
      cursor: pointer;
    }

    :global(.multiselect:not(.open) ul.selected li) {
      background: transparent;
      margin: 0;
      margin-right: 8px;
      padding: 0;
      pointer-events: none;
    }

    :global(.multiselect:not(.open) .remove), :global(.multiselect:not(.open) .max-select-msg) {
      display: none;
    }

    :global(.multiselect ul.selected) {
      padding-left: 0.5em;

      :global(input::placeholder) {
        padding-left: 0 !important;
      }
    }

    &.preventClicks :global(.multiselect.open ul.selected) {
      pointer-events: none;
    }

    &.required {
      --sms-placeholder-color: rgba(54, 54, 54, 0.3); // Default placeholder color
    }

    :global(.multiselect.disabled) {
      // Same styles as .input[disabled]
      background-color: whitesmoke;
      border-color: whitesmoke;
      box-shadow: none;
      color: #7a7a7a;
    }

    :global(.multiselect:focus-within) {
      // Same styles as .input:active
      border-color: $link;
      box-shadow: 0 0 0 0.125em rgba($link, 0.25); // actually `$input-focus-box-shadow-size $input-focus-box-shadow-color` but that's not in _variables and importing Bulma's own variables is overkill
    }

    :global(.multiselect.open) {
      height: auto;
      min-height: 2.5em;
    }
  }
</style>

<main class:preventClicks class:required class:empty use:closeOnArrowClick>
  <MultiSelect
    breakpoint={768}
    {disabled}
    filterFunc={(opt, searchText) =>
      // In case of (W)ETH, match all of ETH, WETH and (W)ETH
      opt.custom.dispSymbol.toLowerCase().replace(/\W/g, '').startsWith(searchText.toLowerCase().replace(/\W/g, '')) ||
      opt.custom.dispSymbol.toLowerCase().replace(/\(.*\)/g, '').includes(searchText.toLowerCase()) ||
      opt.label.match(new RegExp('\\b' + escapeRegex(searchText), 'i'))
    }
    {loading}
    bind:selected={rawSelected}
    {maxSelect}
    bind:open
    {options}
    outerDivClass="input select {(required && empty) ? 'is-danger' : ''}"
    placeholder={placeholder ?? (maxSelect !== 1 ? 'Select assets' : 'Select asset')}
    {required}
    on:mousedown={onMouseDown}
    on:change={onChange}
    on:add
    on:remove
    on:removeAll
    on:change
    on:open
    on:close
  >
    <svelte:fragment slot="option" let:option>
      <img class="coinIcon" src="{option.custom.coin.iconUrl}" alt="{option.custom.coin.symbol}" />
      <strong>{option.custom.dispSymbol}</strong>
      <small>{option.custom.dispName}</small>
    </svelte:fragment>

    <svelte:fragment slot="selected" let:option>
      {#if maxSelect !== 1}
        <img class="coinIcon mr-0" src="{option.custom.coin.iconUrl}" alt="{option.custom.coin.symbol}" title="{option.custom.dispSymbol}: {option.label}" />
      {:else}
        <img class="coinIcon" src="{option.custom.coin.iconUrl}" alt="{option.custom.coin.symbol}" title="{option.custom.dispSymbol}: {option.label}" />
        <strong>{option.custom.dispSymbol}</strong>
      {/if}
    </svelte:fragment>

    <svelte:fragment slot="spinner">
      <span class="loader mx-1"></span>
    </svelte:fragment>
  </MultiSelect>
</main>
