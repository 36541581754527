import { connectedWalletAddress, registeredWallets, currentProvider } from './walletManager'
import { get, derived } from 'svelte/store'
import { delay } from '../lib/utils'
import AsyncLock from 'async-lock'

const FAILURE_TOPICS = [
  '0x45b96fe442630264581b197e84bbada861235052c5a1aadfff9ea4e40a969aa0' // Compound "Failure (uint256 error, uint256 info, uint256 detail)"
]

const lock = new AsyncLock()

let provider
currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

export function getTransactionFromWalletObject (wallet, hash, allowUndefinedWallet = false) {
  const history = wallet?.history
  if (!history) {
    if (allowUndefinedWallet) return undefined
    throw new Error('Wallet address not found')
  }
  return history.find(tx => tx.hash === hash)
}

export function getTransaction (walletAddress, hash) {
  return getTransactionFromWalletObject(get(registeredWallets)[walletAddress], hash)
}

export function updateTransaction (walletAddress, hash, data) {
  registeredWallets.update($registeredWallets => {
    const tx = getTransactionFromWalletObject($registeredWallets[walletAddress], hash)
    if (!tx) throw new Error('Transaction not found')
    Object.assign(tx, data)
    return $registeredWallets
  })
}

export function upsertTransaction (walletAddress, hash, data) {
  if (!hash) hash = data.hash
  registeredWallets.update($registeredWallets => {
    const history = $registeredWallets[walletAddress]?.history
    if (!history) throw new Error('Wallet address not found')
    const tx = history.find(tx => tx.hash === hash)
    if (tx) {
      Object.assign(tx, data)
    } else {
      history.push({ hash, timestamp: Date.now(), ...data })
    }
    return $registeredWallets
  })
}

export function getTxStore (walletAddress, hash) {
  return derived(registeredWallets, $registeredWallets => getTransactionFromWalletObject($registeredWallets[walletAddress], hash, true))
}

export async function getTxStatusByHash (hash, retry = false) {
  if (!provider) throw new Error('No Ethereum provider found')

  let tx
  let retries = 0
  while (!tx) {
    tx = await provider.send('eth_getTransactionByHash', [hash])

    if (!tx) {
      if (!retry || ++retries >= 300) return { hash, status: 'dropped' }
      await delay(1000)
    }
  }

  const receipt = await provider.send('eth_getTransactionReceipt', [hash])

  if (!receipt) return { hash, tx, status: 'pending' }

  let ok = Number(receipt.status) === 1
  if (receipt.logs.some(log => log.topics.some(topic => FAILURE_TOPICS.includes(topic)))) ok = false

  return { hash, tx, receipt, status: ok ? 'success' : 'failed' }
}

export async function checkPendingTransactions (walletAddress) {
  await lock.acquire(walletAddress, async () => {
    const history = get(registeredWallets)[walletAddress]?.history
    if (!history) throw new Error('Wallet address not found')

    const pendingTransactions = history.filter(tx => tx.status === 'pending')

    await Promise.all(pendingTransactions.map(async tx => {
      const { status, tx: checkedTx } = await getTxStatusByHash(tx.hash)
      const nonce = checkedTx?.nonce
      if (status !== 'pending') {
        if (status === 'dropped' && (tx.timestamp > Date.now() - 600000 && !history.find(tx => tx.nonce === Number(nonce)))) return
        updateTransaction(walletAddress, tx.hash, { status, nonce: Number(nonce) })
      }
    }))
  })
}

let initialized = false
export function initTxManager () {
  if (initialized) return

  let $connectedWalletAddress
  connectedWalletAddress.subscribe(newconnectedWalletAddress => {
    $connectedWalletAddress = newconnectedWalletAddress

    if ($connectedWalletAddress) {
      checkPendingTransactions($connectedWalletAddress).catch(e => console.error('Failed to check pending transactions on active wallet change', e))
    }
  })

  setInterval(() => {
    if ($connectedWalletAddress) {
      checkPendingTransactions($connectedWalletAddress).catch(e => console.error('Failed to check pending transactions in background', e))
    }
  }, 5000)

  initialized = true
}
