<script>
  import markets from '../stores/markets'
  import { quoteCoinSymbol } from '../stores/quoteCoin'
  import LegacyCoinDropdown from './LegacyCoinDropdown.svelte'

  $: selectedCoin = $markets?.quoteCoins[$quoteCoinSymbol] ?? null

  let coinDropdown
  let listOpen = false
  let fieldVisible = false
  let autoHideArmed = false

  function onSelect ({ detail: newSelection }) {
    $quoteCoinSymbol = newSelection?.symbol ?? null
  }

  async function onClick () {
    fieldVisible = true
    setTimeout(() => {
      coinDropdown.focus()
      autoHideArmed = true
    }, 200)
  }

  $: if (autoHideArmed && !listOpen) {
    fieldVisible = false
    autoHideArmed = false
  }
</script>

<style lang="scss">
  a {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  section {
    text-align: var(--text-align, 'left');
    display: inline-block;
    height: 1.875rem;
    width: 50px; // This is used for animating - the min-width is what will actually take effect
    min-width: max-content;
    transition: width 0.2s ease-in-out;

    &.fieldVisible {
      min-width: auto;
      text-align: left;
      width: 150px;
    }
  }
</style>

<section class:fieldVisible>
  {#if fieldVisible}
    <LegacyCoinDropdown small bind:this={coinDropdown} --min-width="none" --width="100%" coinItems={Object.values($markets.quoteCoins)} value={selectedCoin} on:select={onSelect} bind:listOpen />
  {:else}
    <a href={undefined} on:click={onClick}>{$quoteCoinSymbol ?? '...'} <i class="fa fa-caret-down"></i></a>
  {/if}
</section>
