<script>
  import { tick } from 'svelte'
  import { delay, formatCurrency, generateRandomString } from '../lib/utils'
  import { quoteCoin } from '../stores/quoteCoin'
  import { currentProvider } from '../stores/walletManager'

  export let loanPlan

  let activeId = null

  function monitor (el) {
    activeId = el.id

    return {
      destroy () {
        if (activeId === el.id) activeId = null
      }
    }
  }

  async function calculateGasCost () {
    await tick()

    const originalId = activeId
    await delay(1000) // Effectively debounce

    if (activeId !== originalId) return null

    try {
      const steps = await loanPlan.getSteps(true)
      const estimatedGas = steps.reduce((acc, step) => acc + (step._gas ?? 0), 0)

      const price = await $currentProvider.getGasPrice()
      const gasCost = Number(price.mul(estimatedGas)) / 1e18

      return gasCost * $quoteCoin.ethPrice
    } catch (e) {
      console.error('Failed to calculate gas cost', e, loanPlan)
      throw e
    }
  }
</script>

{#key loanPlan}
  <main use:monitor id={generateRandomString(6)}>
    <i class="fa fa-gas-pump"></i>&nbsp;
    {#await calculateGasCost()}
      <span class="loader is-inline-block"></span>
    {:then gasCost}
      {gasCost ? (loanPlan.hasSufficientBalance ? '≈' : 'up to ') : ''}{formatCurrency(gasCost, $quoteCoin.symbol, undefined, '-', true)}
    {:catch error}
      <em class="has-text-grey">{error.message === 'No valid wallet connected' ? 'Connect wallet to estimate.' : 'Estimation failed.'}</em>
    {/await}
  </main>
{/key}
