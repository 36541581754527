<script>
  import WalletActions from '../components/WalletActions.svelte'
  import services from '../lib/services'
  import { formatCurrency, getNonEthAddressShortLabel } from '../lib/utils'
  import { getThorchainTransactionSimplifiedInfo } from '../lib/walletActions/thorchain'
  import { createLoadingStore } from '../stores/loading'
  import { connectedWallet, connectBlocknative, walletConnecting, getAddressShortLabel } from '../stores/walletManager'
  import { Button, Icon } from 'svelma-fixed'
  import { slide } from 'svelte/transition'
  import uri from 'uri-tag'
  import { updateThorchainTransaction } from '../stores/thorchainTxManager'
  import { onMount } from 'svelte'

  let walletActions

  const completeTransactionLoading = createLoadingStore()
  let completeTransactionLoadingId

  $: augmentedTransactions = $connectedWallet?.thorchainTransactions?.map(tx => ({ ...tx, simple: getThorchainTransactionSimplifiedInfo(tx) })).sort((a, b) => b.simple.timestamp - a.simple.timestamp) ?? []
  $: if (augmentedTransactions) handleSeenUpdate()

  function handleSeenUpdate () {
    if (document.hasFocus() && !document.hidden) {
      for (const tx of augmentedTransactions) {
        if (tx.simple.status === 'success' && !tx.wehodlData?.seen) {
          setSeen(tx)
        }
      }
    }
  }

  onMount(() => {
    document.addEventListener('visibilitychange', handleSeenUpdate)
    window.addEventListener('focus', handleSeenUpdate)

    return () => {
      document.removeEventListener('visibilitychange', handleSeenUpdate)
      window.removeEventListener('focus', handleSeenUpdate)
    }
  })

  const getServiceName = service => services[service]?.name ?? service

  async function completeTransaction (tx) {
    completeTransactionLoadingId = tx.simple.in.txId
    await completeTransactionLoading(async () => {
      throw new Error('TODO: Not implemented')
    })
  }

  function setSeen (tx) {
    updateThorchainTransaction($connectedWallet.address, tx.simple.in.txId, { wehodlData: { ...tx.wehodlData, seen: true } })
  }

  async function swapBtcToWbtc () {
    throw new Error('TODO: Not implemented')
  }

  async function swapWbtcToBtc () {
    walletActions.swapToBtc('WBTC')
  }

  function thorchainTxIdToEthHash (obj) {
    return `0x${obj.txId.toLowerCase()}`
  }
</script>

<svelte:head>
	<title>THORChain Transactions - WEHODL</title>
</svelte:head>

<div class="container">
  <h4 class="title is-4 my-3">
    THORChain Transactions
  </h4>

  <div class="box py-2 mb-5">
    <div class="columns">
      <div class="column is-12">WEHODL integrates with <a href="https://thorchain.org/" target="_blank" rel="noopener noreferrer">THORChain</a>, a cross-chain decentralized protocol that allows native swaps between different blockchains, to offer direct and trustless swaps between BTC and WBTC, as only WBTC can be used on Ethereum-based De-Fi platforms.</div>
    </div>
    {#if !$connectedWallet}
      <div class="notification is-info mb-3" transition:slide|local>
        {#if $walletConnecting}
          <Icon icon="spinner" customClass="fa-pulse" /> Connecting wallet...
        {:else}
          <Icon icon="info-circle" /> You need to <a href={undefined} on:click={() => connectBlocknative()}>connect your wallet</a> to use this feature!
        {/if}
      </div>
    {:else}
      <div class="columns mb-4" transition:slide|local>
        <div class="column is-half has-text-centered">
          <Button type="is-primary" disabled on:click={swapBtcToWbtc}>Swap BTC to WBTC</Button>
          <div><i>Coming soon!</i></div>
        </div>
        <div class="column is-half has-text-centered">
          <Button type="is-primary" on:click={swapWbtcToBtc}>Swap WBTC to BTC</Button>
        </div>
      </div>
    {/if}
  </div>

  {#each augmentedTransactions as tx (tx.simple.in.txId)}
    <div class="box py-2 mb-3" transition:slide|local>
      <div class="columns">
        <div class="column is-2">
          <div class="is-size-7 has-text-grey">Date</div>
          <div>{new Date(tx.simple.timestamp).toLocaleString()}</div>
        </div>
        <div class="column is-2">
          <div class="is-size-7 has-text-grey">From</div>
          <div>{formatCurrency(tx.simple.in.amount, undefined, -6, '-', true)} {tx.simple.in.symbol}</div>
        </div>
        <div class="column is-2">
          <div class="is-size-7 has-text-grey">To</div>
          <div>{#if tx.simple.out.amount}{tx.simple.status !== 'success' ? '≈' : ''}{formatCurrency(tx.simple.out.amount, undefined, -6, '-', true)} {/if}{tx.simple.out.symbol}</div>
        </div>
        <div class="column is-2">
          <div class="is-size-7 has-text-grey">Input TX ID</div>
          <div>
            {#if tx.simple.in.assetId.startsWith('ETH.')}
              <a href={uri`https://etherscan.io/tx/${thorchainTxIdToEthHash(tx.simple.in)}`} target="_blank" rel="noopener noreferrer">{getAddressShortLabel(thorchainTxIdToEthHash(tx.simple.in))}</a>
            {:else if tx.simple.in.assetId.startsWith('BTC.')}
              <a href={uri`https://blockchair.com/bitcoin/transaction/${tx.simple.in.txId}`} target="_blank" rel="noopener noreferrer">{getNonEthAddressShortLabel(tx.simple.in.txId)}</a>
            {:else}
              {getNonEthAddressShortLabel(tx.simple.in.txId)}
            {/if}
          </div>
        </div>
        <div class="column is-2">
          <div class="is-size-7 has-text-grey">Output TX ID</div>
          <div>
            {#if !tx.simple.out.txId}
              <em class="has-text-grey">N/A</em>
            {:else if tx.simple.out.assetId.startsWith('ETH.')}
              <a href={uri`https://etherscan.io/tx/${thorchainTxIdToEthHash(tx.simple.out)}`} target="_blank" rel="noopener noreferrer">{getAddressShortLabel(thorchainTxIdToEthHash(tx.simple.out))}</a>
            {:else if tx.simple.out.assetId.startsWith('BTC.')}
              <a href={uri`https://blockchair.com/bitcoin/transaction/${tx.simple.out.txId}`} target="_blank" rel="noopener noreferrer">{getNonEthAddressShortLabel(tx.simple.out.txId)}</a>
            {:else}
              {getNonEthAddressShortLabel(tx.simple.out.txId)}
            {/if}
          </div>
        </div>
        <div class="column is-2 has-text-right">
          <div>
            {#if tx.simple.status === 'success'}
              <span class="tag is-success">Success</span>
            {:else if tx.simple.status === 'failed'}
              <span class="tag is-danger">Failed</span>
            {:else if tx.simple.status === 'pending'}
              <Icon icon="spinner" customClass="fa-pulse" /> <span class="tag is-warning">Pending</span>
            {:else}
              <span class="tag is-dark">Unknown</span>
            {/if}
          </div>
          <div class="is-size-7">
            {#if tx.status}
              <a href={uri`https://thorchain.net/tx/${tx.simple.in.txId}`} target="_blank" rel="noopener noreferrer">View on THORChain</a>
            {/if}
          </div>
        </div>
      </div>
      {#if tx.wehodlData?.followUpAction}
        <div class="notification is-warning" class:is-light={!tx.wehodlData?.followUpAction} transition:slide|local>
          <p>
            <strong>Manual action is required to complete your planned {tx.wehodlData?.followUpAction.type} operation on {getServiceName(tx.wehodlData?.followUpAction.service)}.</strong>
          </p>
          <p>
            <Button outlined inverted color="is-warning" iconRight="arrow-right" on:click={() => completeTransaction(tx)} loading={$completeTransactionLoading && completeTransactionLoadingId === tx.simple.in.txId} disabled={$completeTransactionLoading && completeTransactionLoadingId !== tx.simple.in.txId}>Complete {tx.wehodlData?.followUpAction.type} operation now</Button>
          </p>
        </div>
      {/if}
    </div>
  {:else}
    <div class="box py-2 mb-3" transition:slide|local>
      <div class="columns">
        <div class="column is-12 has-text-centered has-text-grey py-5">
          <em>Your THORChain transactions will appear here.</em>
        </div>
      </div>
    </div>
  {/each}
</div>

<WalletActions bind:this={walletActions} />
