<script>
  import QuoteCoinSelector from '../components/QuoteCoinSelector.svelte'
  import WalletManager from '../components/WalletManager.svelte'
  import WalletToolbar from '../components/WalletToolbar.svelte'
  import dialogs from '../stores/dialogs'
  import { quoteCoin } from '../stores/quoteCoin'
  import { activeWalletAddress } from '../stores/walletManager'
  import { Button } from 'svelma-fixed'
  import markets from '../stores/markets'
  import CoinMultiSelect from '../components/CoinMultiSelect.svelte'

  const coinItems = Object.values($markets.coins).filter(coin => !coin.hidden)
  let selectedCoins = ['USDT', 'USDC', 'DAI', 'ETH', 'WBTC']
  let coinMultiSelectDisabled = false
  let coinMultiSelectLoading = false
  let coinMultiSelectMulti = true
</script>

<svelte:head>
	<title>Component Test - WEHODL</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Component Test Page
    </h4>
    <QuoteCoinSelector />
    <p>Quote coin: {JSON.stringify($quoteCoin)}</p>
    <hr />
    <WalletToolbar />
    <p>Active wallet address: {$activeWalletAddress}</p>
    <hr />
    <Button on:click={() => dialogs.open(WalletManager)}>Open wallet manager</Button>
    <hr />
    <CoinMultiSelect maxSelect={coinMultiSelectMulti ? 5 : 1} {coinItems} bind:selected={selectedCoins} disabled={coinMultiSelectDisabled} loading={coinMultiSelectLoading} />
    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={coinMultiSelectDisabled}> Disabled
      </label>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={coinMultiSelectLoading}> Loading
      </label>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={coinMultiSelectMulti}> Multi
      </label>
    </div>
    <p>Selected coins: {selectedCoins.join(', ')}</p>
  </div>
</div>
