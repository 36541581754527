<svelte:options accessors />

<script>
  import Select from 'svelte-select'
  import html from 'html-template-tag'

  export let coinItems
  export let value
  export let small = false
  export let disabled
  export let listOpen
  export let filterText
  export let input
  export let isFocused

  $: if (!listOpen) filterText = ''

  export function focus () {
    input?.focus()
    input?.dispatchEvent(new MouseEvent('focus'))
    isFocused = true
    listOpen = true
  }

  function getIcon (coin) {
    if (coin.iconUrl) {
      return html`<img class="coinIcon" src="${coin.iconUrl}" alt="${coin.symbol}" />`
    } else {
      return ''
    }
  }
</script>

<style lang="scss">
  .control {
    color: $text;
    text-align: left;

    min-width: var(--min-width, 275px);
    width: var(--width, auto);

    :global(small) {
      font-size: 0.6em;
    }

    :global(strong) {
      color: inherit;
    }

    &::after {
      right: 0.75em !important;
    }

    :global(.selectContainer) {
      height: 2.5em !important;
    }

    :global(.listContainer) {
      font-size: initial;
    }

    :global(input) {
      font-size: inherit;
      height: 100% !important;
      text-align: left !important;
    }

    :global(img.coinIcon) {
      width: 16px;
      height: 16px;
      object-fit: contain;
      vertical-align: middle;
      margin-right: 8px;
    }

    :global(.in-use-badge) {
      display: inline-block;
      font-size: 60%;
      line-height: 1;
      background-color: #999999;
      color: white;
      text-transform: uppercase;
      padding: 2px 4px;
      transform: translateY(-2px);
      border-radius: 999px; // max.
    }
  }
</style>

<div class="control select" class:is-small={small}>
  <Select
    bind:value
    bind:listOpen
    bind:filterText
    bind:input
    bind:isFocused
    items={coinItems}
    optionIdentifier=symbol
    labelIdentifier=name
    getOptionLabel={option => {
      let badge = ''
      if (option.inUse) {
        badge = html`<span class="in-use-badge">In Use</span>`
      } else if (option.removeOnly) {
        badge = html`<span class="in-use-badge">Frozen</span>`
      }
      return html`$${getIcon(option)}<strong>${option.dispSymbol ?? option.symbol}</strong> $${badge ?? ''}<small ${badge ? 'hidden' : ''}>${option.name}</small>`
    }}
    getSelectionLabel={option => html`$${getIcon(option)}${option.dispSymbol ?? option.symbol}`}
    isDisabled={disabled}
    isClearable={false}
    inputAttributes={{ class: 'input' }}
    on:select
  />
</div>
