<script>
  import { registeredWallets, removeWallet, renameAddress, activeWalletAddress, setImportedWallet, updateLiveDataIfNeeded, getWalletName, getAddressShortLabel } from '../stores/walletManager'
  import { createEventDispatcher } from 'svelte'
  import { Button, Icon } from 'svelma-fixed'
  import Tooltip from './Tooltip.svelte'
  import ModalDialog from './ModalDialog.svelte'

  const dispatch = createEventDispatcher()

  function select (address) {
    setImportedWallet(address)
    updateLiveDataIfNeeded()
    dispatch('close')
  }
</script>

<style lang="scss">
  div.wallet-row {
    border-bottom: 1px solid #e7e7e7;
    padding: 4px 0;
    margin: 0;

    &:last-child {
      border-bottom: none;
    }
  }

  small {
    display: block;
    font-size: 63%;
    margin: -5px 0;
  }

  .level-left {
    display: block;
  }

  .level-right {
    gap: 4px;
    margin-top: 0;
  }
</style>

<ModalDialog on:close title="Previous Wallets" closeable>
  {#each Object.values($registeredWallets).sort((a, b) => (b.lastSelected || 0) - (a.lastSelected || 0)) as wallet (wallet.address)}
    <div class="wallet-row level">
      <div class="level-left">
        {#if wallet.address !== $activeWalletAddress}
          <a href={undefined} on:click={() => select(wallet.address)}>{getWalletName(wallet)}</a>
        {:else}
          <strong>{getWalletName(wallet)}</strong>
        {/if}

        {#if wallet.name}
          <small>{getAddressShortLabel(wallet.address)}</small>
        {/if}
      </div>
      <div class="level-right">
        <Tooltip label="Rename" position="is-left">
          <Button size="is-small" outlined on:click={() => renameAddress(wallet.address)}><Icon size="is-small" icon="pen" /></Button>
        </Tooltip>
        <Tooltip label="Delete" position="is-left">
          <Button size="is-small" outlined on:click={() => removeWallet(wallet.address)}><Icon size="is-small" icon="trash-alt" /></Button>
        </Tooltip>
      </div>
    </div>
  {:else}
    <p>No registered wallets.</p>
  {/each}
</ModalDialog>
