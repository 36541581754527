<script>
  import ModalDialog from './ModalDialog.svelte'
  import { Button } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { formatCurrency } from '../lib/utils'

  const dispatch = createEventDispatcher()

  export let symbol
  export let stableAmount
  export let variableAmount
</script>

<ModalDialog title="Repay Priority" closeable on:close>
  <p class="mb-3"><strong>You have multiple {symbol} debt positions with different interest rate modes:</strong></p>

  <p class="mb-3">
    <strong>Variable:</strong> {formatCurrency(variableAmount, undefined, -6, '-', true)} {symbol}
    <strong>Stable:</strong> {formatCurrency(stableAmount, undefined, -6, '-', true)} {symbol}<br />
  </p>

  <p>Please select whether you want to repay the stable interest debt position or the variable interest debt position first.</p>

  <svelte:fragment slot="footer">
    <Button type="is-primary" on:click={() => dispatch('close', 'Variable')}>Variable First</Button>
    <Button type="is-primary" on:click={() => dispatch('close', 'Stable')}>Stable First</Button>
    <Button on:click={() => dispatch('close')}>Cancel</Button>
  </svelte:fragment>
</ModalDialog>
