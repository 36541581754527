import { ALL, HALF_MAX_UINT256, MAX_UINT256, getAllowance, getAmountForDisplay, getAmountInSmallestUnit, getApproveStep, getErc20Balance, verifyMainnetChain, waitForTxHashOnly } from '.'
import { connectedWalletAddress, toChecksumAddress, currentProvider } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import markets from '../../stores/markets'
import * as ethers from 'ethers'
import { formatCurrency } from '../utils'
import compoundV3ImplementationAbi from '../abi/compoundV3ImplementationAbi.json'
import compoundV3WethBulkerAbi from '../abi/compoundV3WethBulkerAbi.json'

export const WETH_TOKEN_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
export const COMPOUND_V3_ETHEREUM_MAINNET_PROTOCOL_ADDRESS = '0x316f9708bb98af7da9c68c1c3b5e79039cd336e3'
export const CUSDCV3_ADDRESS = '0xc3d688b66703497daa19211eedff47f25384cdc3'
export const USDC_ADDRESS = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
export const COMPOUND_V3_WETH_BULKER_ADDRESS = '0xa397a8c2086c554b531c02e29f3291c9704b00c7'

let provider

currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!provider) throw new Error('Provider unavailable')
}

const protocolAddresses = {
  USDC: CUSDCV3_ADDRESS
}

export async function getProtocolContract (symbol) {
  if (!protocolAddresses[symbol]) throw new Error(`No protocol address found for ${symbol}`)
  const contract = await new ethers.Contract(protocolAddresses[symbol], compoundV3ImplementationAbi, provider.getSigner())
  return contract
}

export async function getBulkerContract () {
  return await new ethers.Contract(COMPOUND_V3_WETH_BULKER_ADDRESS, compoundV3WethBulkerAbi, provider.getSigner())
}

export async function getWethBulkerAllowStep (baseSymbol, protocolContract) {
  const isAllowed = await protocolContract.callStatic.hasPermission(get(connectedWalletAddress), COMPOUND_V3_WETH_BULKER_ADDRESS)
  if (!isAllowed) {
    return getApproveStep('ETH', async () => ({ promise: protocolContract.allow(COMPOUND_V3_WETH_BULKER_ADDRESS, true) }), `compoundV3_${baseSymbol}`, 'Approve WETH bulker contract', 'Approve Compound V3 WETH bulker contract access for managing portfolio')
  } else {
    return undefined
  }
}

async function supply (contract, isNativeToken, tokenAddress, amount) {
  if (isNativeToken) {
    const bulker = await getBulkerContract()
    const supplyNativeTokenCalldata = ethers.utils.defaultAbiCoder.encode(['address', 'address', 'uint'], [contract.address, get(connectedWalletAddress), amount])
    return bulker.invoke([ethers.utils.formatBytes32String('ACTION_SUPPLY_NATIVE_TOKEN')], [supplyNativeTokenCalldata], { value: amount })
  } else {
    return contract.supply(tokenAddress, amount)
  }
}

async function withdraw (contract, isNativeToken, tokenAddress, amount) {
  if (isNativeToken) {
    const bulker = await getBulkerContract()
    const withdrawNativeTokenCalldata = ethers.utils.defaultAbiCoder.encode(['address', 'address', 'uint'], [contract.address, get(connectedWalletAddress), amount])
    return bulker.invoke([ethers.utils.formatBytes32String('ACTION_WITHDRAW_NATIVE_TOKEN')], [withdrawNativeTokenCalldata])
  } else {
    return contract.withdraw(tokenAddress, amount)
  }
}

export async function compoundV3ActionDeposit (baseSymbol, symbol, amount) {
  await verifyPreconditions()

  const contract = await getProtocolContract(baseSymbol)

  const steps = []

  if (amount === ALL) {
    if (symbol === 'ETH') throw new Error('ALL is not supported for ETH')

    amount = await getErc20Balance(symbol)
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (symbol === 'ETH') {
    const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    if (approvalStep) steps.push(approvalStep)
  }

  if (Number(amount) > 0) {
    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(getApproveStep(symbol, contract.address, `compoundV3_${baseSymbol}`))
    }

    steps.push({
      id: 'deposit',
      description: `Supply ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to protocol`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const hash = await waitForTxHashOnly(supply(contract, symbol === 'ETH', tokenAddress, amountInSmallestUnit), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: `compoundV3_${baseSymbol}`,
            type: 'deposit',
            symbol,
            amount: await getAmountForDisplay(symbol, amount)
          },
          icon: 'sign-in-alt',
          description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Compound V3 ${baseSymbol}`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })
  }

  return steps
}

export async function compoundV3ActionWithdraw (baseSymbol, symbol, amount) {
  await verifyPreconditions()

  const contract = await getProtocolContract(baseSymbol)

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  let actualAmount = amount
  if (amount === ALL) {
    if (symbol === baseSymbol) {
      actualAmount = MAX_UINT256
    } else {
      actualAmount = (await contract.callStatic.userCollateral(get(connectedWalletAddress), tokenAddress))[0]
    }
  }

  if (symbol === 'ETH') {
    const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    if (approvalStep) steps.push(approvalStep)
  }

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)

  steps.push({
    id: 'withdraw',
    description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from protocol`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = await waitForTxHashOnly(withdraw(contract, symbol === 'ETH', tokenAddress, amountInSmallestUnit), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: `compoundV3_${baseSymbol}`,
          type: 'withdraw',
          symbol,
          ...amount === ALL ? { all: true } : { amount: Number(amount) }
        },
        icon: 'sign-out-alt',
        description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from Compound V3 ${baseSymbol}`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function compoundV3ActionBorrow (baseSymbol, symbol, amount) {
  await verifyPreconditions()

  const contract = await getProtocolContract(baseSymbol)

  const [baseBorrowMin, borrowBalance] = await Promise.all([
    contract.callStatic.baseBorrowMin(),
    contract.callStatic.borrowBalanceOf(get(connectedWalletAddress))
  ])

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (amount === ALL) throw new Error('ALL is not supported for borrow')

  if (symbol === 'ETH') {
    const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    if (approvalStep) steps.push(approvalStep)
  }

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)

  if (borrowBalance.add(amountInSmallestUnit).lt(baseBorrowMin)) {
    throw new Error(`Borrow balance cannot be less than ${await getAmountForDisplay(symbol, baseBorrowMin)} ${symbol} for this protocol! You need to borrow a larger amount.`)
  }

  steps.push({
    id: 'borrow',
    description: `Withdraw ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from protocol`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = await waitForTxHashOnly(withdraw(contract, symbol === 'ETH', tokenAddress, amountInSmallestUnit), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: `compoundV3_${baseSymbol}`,
          type: 'borrow',
          symbol,
          amount: Number(amount)
        },
        icon: 'sign-out-alt',
        description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from Compound V3 ${baseSymbol}`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function compoundV3ActionRepay (baseSymbol, symbol, amount) {
  await verifyPreconditions()

  const contract = await getProtocolContract(baseSymbol)

  const steps = []

  let actualAmount = amount
  if (amount === ALL) {
    actualAmount = MAX_UINT256
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (symbol === 'ETH') {
    const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    if (approvalStep) steps.push(approvalStep)
  }

  // We are comparing with half the maxiumum allowance here in case there are contracts that do not handle -1 separately and have already deducted a bit.
  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)
  const amountInSmallestUnitForApprovalCheck = amount === ALL ? HALF_MAX_UINT256 : await getAmountInSmallestUnit(symbol, actualAmount)
  if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnitForApprovalCheck === 'number' ? amountInSmallestUnitForApprovalCheck.toFixed(0) : amountInSmallestUnitForApprovalCheck))) {
    steps.push(getApproveStep(symbol, contract.address, `compoundV3_${baseSymbol}`))
  }

  if (amount !== ALL) {
    const [baseBorrowMin, borrowBalance] = await Promise.all([
      contract.callStatic.baseBorrowMin(),
      contract.callStatic.borrowBalanceOf(get(connectedWalletAddress))
    ])

    if (borrowBalance.sub(amountInSmallestUnit).lt(baseBorrowMin)) throw new Error(`Borrow balance cannot be less than ${await getAmountForDisplay(symbol, baseBorrowMin)} ${symbol} for this protocol! You need to repay a smaller amount or repay the whole loan at once.`)
  }

  steps.push({
    id: 'repay',
    description: `Supply ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to protocol`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = await waitForTxHashOnly(supply(contract, symbol === 'ETH', tokenAddress, amountInSmallestUnit), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: `compoundV3_${baseSymbol}`,
          type: 'repay',
          symbol,
          ...amount === ALL ? { all: true } : { amount: Number(amount) }
        },
        icon: 'sign-in-alt',
        description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Compound V3 ${baseSymbol}`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}
