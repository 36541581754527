<script>
  import Tooltip, { Wrapper } from '@smui/tooltip'
  import { Icon } from 'svelma-fixed'
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  main {
    display: contents;

    :global(.mdc-tooltip-wrapper--rich) {
      display: inline-block;
      position: relative;
      height: 1em;
    }

    :global(.mdc-tooltip--rich .mdc-tooltip__surface) {
      line-height: 1.5;
    }

    :global(.mdc-tooltip) {
      @include mobile {
        left: -150px !important;
      }

      @include tablet-only {
        left: -200px !important;
      }
    }
  }

  @include tablet {
    :global(.info-tooltip-area) span {
      opacity: 0;
      pointer-events: none;
    }

    :global(.info-tooltip-area:hover) span {
      opacity: 1;
      pointer-events: auto;
    }
  }

  span {
    cursor: help;
    transition: opacity 0.2s ease-in-out;

    position: absolute;
    left: -0.15em;
    bottom: -40%;

    :global(.icon) {
      font-size: 0.75em;
    }
  }

  aside {
    color: black !important;
    min-width: 200px;
    text-align: left;

    :global(p) {
      margin-bottom: 1em;
    }

    :global(ul) {
      margin-bottom: 1em;
    }

    :global(hr) {
      margin: 0;
    }
  }
</style>

<main>
  <Wrapper rich>
    <span>
      <sup><Icon icon="circle-info" size="is-small" /></sup>
    </span>

    <Tooltip interactive>
      <aside>
        <slot />
      </aside>
    </Tooltip>
  </Wrapper>
</main>
