<script>
  import DetailsTable from './DetailsTable.svelte'
  import markets from '../stores/markets'
  import { quoteCoin } from '../stores/quoteCoin'
  import { formatCurrency } from '../lib/utils'
  import { Input } from 'svelma-fixed'
  import services from '../lib/services'

  export let search = ''
  export let assets = []
  export let showTotal = false

  $: totalEthValue = assets.reduce((acc, asset) => acc + asset.ethValue ?? 0, 0)

  const filterAssets = assets => assets.filter(asset => !search || asset.symbol.toLowerCase().startsWith(search.toLowerCase()))
  const getServiceName = service => services[service]?.name ?? service
</script>

<DetailsTable>
  <div class="level">
    <div class="level-left">
      <Input bind:value={search} placeholder="Search asset" type="search" size="is-small" expanded />
    </div>
    <div class="level-right">
      {#if showTotal}
        <strong>{showTotal === true ? 'Total' : showTotal}: {formatCurrency(totalEthValue * $quoteCoin?.ethPrice, $quoteCoin?.symbol, undefined, undefined, true)}</strong>
      {/if}
    </div>
  </div>

  {#each filterAssets(assets) as asset (asset.symbol)}
    <div class="level is-mobile">
      <div class="level-left">
        <figure class="level-item mr-2 image coin-inline-icon"><img src="{$markets.coins[asset.symbol]?.iconUrl ?? $markets.DEFAULT_ICON_URL}" alt="{asset.symbol}"></figure>
        <span class="level-item">{formatCurrency(asset.units, undefined, -6, '-', true)} {asset.symbol} {asset.service ? ` on ${getServiceName(asset.service)}` : ''}</span>
      </div>
      <div class="level-right is-flex-grow-1">
        <span class="level-item is-justify-content-flex-end">{formatCurrency(asset.ethValue * $quoteCoin?.ethPrice, $quoteCoin?.symbol, undefined, undefined, true)}</span>
      </div>
    </div>
  {:else}
    {#if search}
      <div>There are no assets matching "{search}".</div>
    {:else}
      <div>No assets found.</div>
    {/if}
  {/each}

  {#if $$slots.footer}
    <div><slot name="footer" /></div>
  {/if}
</DetailsTable>
