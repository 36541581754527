<script>
  import ErrorBox from '../components/ErrorBox.svelte'
  import Loader from '../components/Loader.svelte'
  import markets from '../stores/markets'
  import services from '../lib/services'
  import { Tabs, Tab, Field, Input, Button, Dialog } from 'svelma-fixed'
  import Card from '../components/Card.svelte'
  import LegacyCoinDropdown from '../components/LegacyCoinDropdown.svelte'
  import RadioGroup from '../components/RadioGroup.svelte'
  import html from 'html-template-tag'
  import Compound from '@compound-finance/compound-js'
  import * as aave from '@aave/protocol-js'

  const compound = new Compound(window.ethereum)
  const aaveTxBuilder = window.ethereum && new aave.TxBuilderV2(aave.Network.mainnet, window.ethereum)
  const aaveLendingPoolRegular = aaveTxBuilder?.getLendingPool(aave.Market.Proto)
  const aaveLendingPoolAmm = aaveTxBuilder?.getLendingPool(aave.Market.AMM)
  const getAaveLendingPoolForAsset = symbol => symbol.startsWith('Amm') ? aaveLendingPoolAmm : aaveLendingPoolRegular

  const aaveReferralCode = 0 // We can get this from Aave!

  // Fix problem that Compound.js was reading address from MetaMask in the wrong way, resulting in an "invalid name" error in some methods
  Object.defineProperty(compound._provider, 'address', {
    get: function () {
      return this.provider?.provider?.selectedAddress
    }
  })

  let address

  async function load () {
    if (window.ethereum?.isMetaMask) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      if (accounts.length) {
        address = accounts[0]
      } else {
        throw new Error('No accounts found')
      }
    }
  }

  window.ethereum?.on('accountsChanged', async accounts => {
    if (accounts.length) {
      address = accounts[0]
    } else {
      address = undefined
      loadingPromise = undefined
    }
  })

  let loadingPromise = load()

  let actionValues = {}
  for (const service in services) {
    actionValues[service] = {
      depositCollateral: {
        loading: false,
        coin: undefined,
        units: ''
      },
      withdrawCollateral: {
        loading: false,
        coin: undefined,
        units: ''
      },
      enableCollateral: {
        loading: false,
        coin: undefined
      },
      disableCollateral: {
        loading: false,
        coin: undefined
      },
      borrow: {
        loading: false,
        coin: undefined,
        mode: undefined,
        units: ''
      },
      repay: {
        loading: false,
        coin: undefined,
        units: ''
      },
      claimComp: {
        loading: false
      },
      changeBorrowRateMode: {
        loading: false,
        coin: undefined,
        mode: undefined
      }
    }
  }

  function getCoinItems (service, type) {
    return Object.values($markets.coins).filter(coin => coin.services[service]?.[type + 'Active'])
  }

  async function actionWrapper (values, fn) {
    values.loading = true
    actionValues = actionValues

    try {
      const txId = await fn()

      if (!txId) return

      console.log('TX ID:', txId)
      Dialog.alert({
        title: 'Success',
        message: html`
          <p>
            Your transaction has been submitted to the network.
          </p>
          <p>
            Transaction ID:<br />
            <a href="https://etherscan.io/tx/${txId}" target="_blank" style="word-break: break-word;">${txId}</a>
          </p>
        `,
        type: 'is-success'
      })
    } catch (err) {
      console.error(err)

      const errorMessage = err.message.includes('See {error}') ? err.error.message : err.message

      Dialog.alert({
        title: 'Error',
        message: html`
          <p>
            ${errorMessage}
          </p>
        `,
        type: 'is-danger'
      })

      throw err
    } finally {
      values.loading = false
      actionValues = actionValues
    }
  }

  async function handleAaveTxArray (txs) {
    for (const tx of txs) {
      const txData = await tx.tx()
      console.log(`Sending TX ${tx.txType}`, txData)
      tx.hash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [txData]
      })
      console.log(`TX ${tx.txType} sent with hash ${tx.hash}`)
    }

    return { hash: txs.at(-1).hash }
  }

  async function depositCollateral (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.supply(Compound[values.coin.symbol], Number(values.units))
      } else if (service === 'aaveV2') {
        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).deposit({
          user: address,
          reserve: values.coin.tokenAddress,
          amount: String(Number(values.units)),
          referralCode: aaveReferralCode
        })
        console.log('Aave deposit TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Deposit collateral TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function withdrawCollateral (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.redeem(Compound[values.coin.symbol], Number(values.units))
      } else if (service === 'aaveV2') {
        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).withdraw({
          user: address,
          reserve: values.coin.tokenAddress,
          amount: String(Number(values.units))
          // TODO: Support ETH gateway, there we also need aToken argument!
        })
        console.log('Aave withdraw TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Withdraw collateral TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function enableCollateral (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.enterMarkets(Compound[values.coin.symbol])
      } else if (service === 'aaveV2') {
        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).setUsageAsCollateral({
          user: address,
          reserve: values.coin.tokenAddress,
          usageAsCollateral: true
        })
        console.log('Aave enable TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Enable collateral TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function disableCollateral (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.exitMarket(Compound[values.coin.symbol])
      } else if (service === 'aaveV2') {
        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).setUsageAsCollateral({
          user: address,
          reserve: values.coin.tokenAddress,
          usageAsCollateral: false
        })
        console.log('Aave disable TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Disable collateral TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function borrow (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.borrow(Compound[values.coin.symbol], Number(values.units))
      } else if (service === 'aaveV2') {
        if (!values.mode) throw new Error('No borrow rate mode selected')

        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).borrow({
          user: address,
          reserve: values.coin.tokenAddress,
          amount: String(Number(values.units)),
          interestRateMode: values.mode,
          referralCode: aaveReferralCode
          // TODO: Support ETH gateway, there we also need debtTokenAddress argument!
        })
        console.log('Aave borrow TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Borrow TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function repay (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'compoundV2') {
        if (!Compound[values.coin.symbol]) throw new Error(`Coin ${values.coin.symbol} not found in Compound SDK`)
        tx = await compound.repayBorrow(Compound[values.coin.symbol], Number(values.units), null)
      } else if (service === 'aaveV2') {
        if (!values.mode) throw new Error('No borrow rate mode selected')

        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).repay({
          user: address,
          reserve: values.coin.tokenAddress,
          amount: String(Number(values.units)), // TODO: Support full amount = -1
          interestRateMode: values.mode // Why?
        })
        console.log('Aave repay TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Repay TX', tx)
      return tx.hash
    }).catch(() => {})
  }

  async function claimComp (service, values) {
    await actionWrapper(values, async () => {
      let tx
      if (service === 'compoundV2') {
        const acc = await Compound.comp.getCompAccrued(address)
        const accUnits = acc / 1e18

        if (!accUnits) {
          await Dialog.alert({
            message: 'You have no accrued interest.',
            type: 'is-info'
          })
        } else if (await Dialog.confirm({
          message: html`
            <p>
              You have ${accUnits} COMP accrued interest.
            </p>
            <p>
              Do you want to claim it now?
            </p>
          `,
          type: 'is-primary'
        })) {
          tx = await compound.claimComp()
        }
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }

      if (!tx) return
      console.log('Claim Comp TX', tx)
      return tx.hash
    }).catch(() => {})
  }
  async function changeBorrowRateMode (service, values) {
    await actionWrapper(values, async () => {
      if (!values.coin) throw new Error('No coin selected')

      let tx
      if (service === 'aaveV2') {
        if (!values.mode) throw new Error('No borrow rate mode selected')

        const txs = await getAaveLendingPoolForAsset(values.coin.symbol).swapBorrowRateMode({
          user: address,
          reserve: values.coin.tokenAddress,
          interestRateMode: values.mode
        })
        console.log('Aave change borrow rate mode TX result array:', txs)

        tx = await handleAaveTxArray(txs)
      } else {
        throw new Error(`Unsupported service: ${service}`)
      }
      console.log('Change borrow rate mode TX', tx)
      return tx.hash
    }).catch(() => {})
  }
</script>

<svelte:head>
	<title>TX Test - WEHODL</title>
</svelte:head>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;

    @include touch {
      grid-template-columns: 1fr;
    }
  }
</style>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      TX Test Page
    </h4>
    {#if window.ethereum?.isMetaMask}
      {#if loadingPromise}
        {#await loadingPromise}
          <Loader />
        {:then}
          <p class="mb-4">Address: {address}</p>
          <Tabs>
            {#each Object.values(services) as { id, name }}
              <Tab label={name}>
                <div class="two-columns">
                  <Card title="Deposit Collateral" icon="sign-in-alt">
                    <form on:submit|preventDefault={() => depositCollateral(id, actionValues[id].depositCollateral)}>
                      <fieldset disabled={actionValues[id].depositCollateral.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'collateral')} bind:value={actionValues[id].depositCollateral.coin} disabled={actionValues[id].depositCollateral.loading} />
                        </Field>
                        <Field label="Units">
                          <Input required bind:value={actionValues[id].depositCollateral.units} />
                        </Field>
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].depositCollateral.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  <Card title="Withdraw Collateral" icon="sign-out-alt">
                    <form on:submit|preventDefault={() => withdrawCollateral(id, actionValues[id].withdrawCollateral)}>
                      <fieldset disabled={actionValues[id].withdrawCollateral.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'collateral')} bind:value={actionValues[id].withdrawCollateral.coin} disabled={actionValues[id].withdrawCollateral.loading} />
                        </Field>
                        <Field label="Units">
                          <Input required bind:value={actionValues[id].withdrawCollateral.units} />
                        </Field>
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].withdrawCollateral.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  <Card title="Enable Collateral" icon="check-circle">
                    <form on:submit|preventDefault={() => enableCollateral(id, actionValues[id].enableCollateral)}>
                      <fieldset disabled={actionValues[id].enableCollateral.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'collateral')} bind:value={actionValues[id].enableCollateral.coin} disabled={actionValues[id].enableCollateral.loading} />
                        </Field>
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].enableCollateral.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  <Card title="Disable Collateral" icon="times-circle">
                    <form on:submit|preventDefault={() => disableCollateral(id, actionValues[id].disableCollateral)}>
                      <fieldset disabled={actionValues[id].disableCollateral.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'collateral')} bind:value={actionValues[id].disableCollateral.coin} disabled={actionValues[id].disableCollateral.loading} />
                        </Field>
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].disableCollateral.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  <Card title="Borrow" icon="sign-out-alt">
                    <form on:submit|preventDefault={() => borrow(id, actionValues[id].borrow)}>
                      <fieldset disabled={actionValues[id].borrow.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'borrow')} bind:value={actionValues[id].borrow.coin} disabled={actionValues[id].borrow.loading} />
                        </Field>
                        <Field label="Units">
                          <Input required bind:value={actionValues[id].borrow.units} />
                        </Field>
                        {#if id === 'aaveV2'}
                          <Field label="Borrow Rate Mode">
                            <RadioGroup bind:value={actionValues[id].borrow.mode} options={[['Stable', 'Stable'], ['Variable', 'Variable']]} disabled={actionValues[id].borrow.loading} />
                          </Field>
                        {/if}
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].borrow.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  <Card title="Repay" icon="sign-in-alt">
                    <form on:submit|preventDefault={() => repay(id, actionValues[id].repay)}>
                      <fieldset disabled={actionValues[id].repay.loading}>
                        <Field label="Coin">
                          <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'borrow')} bind:value={actionValues[id].repay.coin} disabled={actionValues[id].repay.loading} />
                        </Field>
                        <Field label="Units">
                          <Input required bind:value={actionValues[id].repay.units} />
                        </Field>
                        {#if id === 'aaveV2'}
                          <Field label="Borrow Rate Mode">
                            <RadioGroup bind:value={actionValues[id].repay.mode} options={[['Stable', 'Stable'], ['Variable', 'Variable']]} disabled={actionValues[id].repay.loading} />
                          </Field>
                        {/if}
                        <Button type="is-primary" nativeType="submit" loading={actionValues[id].repay.loading}>Submit</Button>
                      </fieldset>
                    </form>
                  </Card>
                  {#if id === 'compoundV2'}
                    <Card title="Check/Claim COMP" icon="coins">
                      <form on:submit|preventDefault={() => claimComp(id, actionValues[id].claimComp)}>
                        <fieldset disabled={actionValues[id].claimComp.loading}>
                          <Button type="is-primary" nativeType="submit" loading={actionValues[id].claimComp.loading}>Check now</Button>
                        </fieldset>
                      </form>
                    </Card>
                  {/if}
                  {#if id === 'aaveV2'}
                    <Card title="Change Borrow Rate Mode" icon="percent">
                      <form on:submit|preventDefault={() => changeBorrowRateMode(id, actionValues[id].changeBorrowRateMode)}>
                        <fieldset disabled={actionValues[id].changeBorrowRateMode.loading}>
                          <Field label="Coin">
                            <LegacyCoinDropdown --min-width="none" --width="100%" coinItems={getCoinItems(id, 'borrow')} bind:value={actionValues[id].changeBorrowRateMode.coin} disabled={actionValues[id].changeBorrowRateMode.loading} />
                          </Field>
                          <Field label="Borrow Rate Mode">
                            <RadioGroup bind:value={actionValues[id].changeBorrowRateMode.mode} options={[['Stable', 'Stable'], ['Variable', 'Variable']]} disabled={actionValues[id].changeBorrowRateMode.loading} />
                          </Field>
                          <Button type="is-primary" nativeType="submit" loading={actionValues[id].changeBorrowRateMode.loading}>Submit</Button>
                        </fieldset>
                      </form>
                    </Card>
                  {/if}
                </div>
              </Tab>
            {/each}
          </Tabs>
        {:catch error}
          <ErrorBox>
            Connection to MetaMask failed - {error}.
            <a href={undefined} on:click={() => (loadingPromise = load())}>Retry</a>
          </ErrorBox>
        {/await}
      {:else}
        Not connected to MetaMask.
        <a href={undefined} on:click={() => (loadingPromise = load())}>Connect</a>
      {/if}
    {:else}
      <ErrorBox>
        You need to install MetaMask to use this page.
      </ErrorBox>
    {/if}
  </div>
</div>
