<script>
  import { createEventDispatcher } from 'svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Button, Field, Input } from 'svelma-fixed'
  import AutoUpdate from './AutoUpdate.svelte'

  const dispatch = createEventDispatcher()

  export let email
  export let lastVerificationEmail = new Date(0)
  export let previousAttemptWasWrong = false

  let formEl

  let tickCounter = 0
  let timeToWait
  $: (tickCounter, timeToWait = Math.max(0, 60 - Math.floor((Date.now() - lastVerificationEmail) / 1000))) // eslint-disable-line no-unused-expressions

  let code = ''

  if (!email) throw new Error('Missing email')

  function isValid (code) {
    return code.match(/^\d{6}$/)
  }

  function submit () {
    code = code.trim()
    if (!isValid(code)) return

    dispatch('close', code)
  }
</script>

<ModalDialog on:close title="Email Verification" closeable on:introend={() => formEl.querySelector('input').focus()} --width="300px">
  <p class="mb-3">
    We sent a verification code to <strong>{email}</strong>.
    Please enter it below to verify your email address.
  </p>

  {#if previousAttemptWasWrong}
    <p class="has-text-danger">
      The code you entered was incorrect. Please try again.
    </p>
  {/if}

  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <fieldset>
      <Field>
        <Input required bind:value={code} name="code" type="text" inputmode="number" pattern="\d{6}" />
      </Field>
    </fieldset>
  </form>

  <p class="mt-3 form-help-text">
    Didn't receive the email?
    {#if timeToWait > 0}
      <AutoUpdate bind:tickCounter always interval={1000}>You can request another email in {timeToWait} seconds.</AutoUpdate>
    {:else}
      <a href={undefined} on:click={() => dispatch('close', 'RESEND')}>Request another verification email</a>
    {/if}
  </p>

  <svelte:fragment slot="footer">
    <Button type="is-primary" on:click={submit} disabled={!isValid(code)}>Submit</Button>
    <Button on:click={() => dispatch('close')}>Cancel</Button>
  </svelte:fragment>
</ModalDialog>
