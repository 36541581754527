<script>
  import { connectedWallet, getWalletName } from '../stores/walletManager'
  import Tooltip from './Tooltip.svelte'
  import WalletDetails from './WalletDetails.svelte'
  import dialogs from '../stores/dialogs'

  export let tooltipPosition = 'is-left'
</script>

<style lang="scss">
  section {
    display: inline-flex;
    line-height: 1.875rem;
    min-height: 1.875rem;
    text-align: left;
    gap: 0.25em;
    align-items: center;
    flex-wrap: wrap;
  }

  .wallet-type-icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    margin: -0.65em -0.25em;
    margin-left: 0.25rem;

    :global(.fa-wallet) {
      font-size: 1.5rem;
    }
  }

  .address-info .wallet-type-icon {
    font-size: 0.75rem;
    vertical-align: middle;
    margin: 0;
    transform: translateY(-2px);
  }

  .address-info {
    margin-left: 0.25em;
    margin-right: 0.25em;
    text-align: right;
  }
</style>

{#if $connectedWallet}
  <section>
    <span class="address-info address">
      <Tooltip label="View Details and Balances" position={tooltipPosition}>
        <a href={undefined} on:click={() => dialogs.open(WalletDetails, { address: $connectedWallet.address })}>
          <span class="wallet-type-icon">
            {@html $connectedWallet.walletTypeIconHtml ?? '<i class="fa fa-wallet"></i>'}
          </span>
          {getWalletName($connectedWallet)}
        </a>
      </Tooltip>
    </span>
  </section>
{/if}
