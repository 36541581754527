'use strict'

import { apiCall } from './api'

// This is loaded once on startup and contains static app data as well the currently logged-in user - it won't change without reload
export const appInfo = window.appInfo = { initialized: false }

export async function initAppInfo () {
  for (const k in appInfo) delete appInfo[k]
  Object.assign(appInfo, await apiCall('GET', '/api/info'), { initialized: true })
}
