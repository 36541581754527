import { ALL, HALF_MAX_UINT256, MAX_UINT256, getAllowance, getAmountForDisplay, getAmountInSmallestUnit, getApproveStep, getErc20Balance, verifyMainnetChain, waitForTxHashOnly } from '.'
import { connectedWalletAddress, toChecksumAddress, currentProvider } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import dialogs from '../../stores/dialogs'
import RepayPriorityPrompt from '../../components/RepayPriorityPrompt.svelte'
import markets from '../../stores/markets'
import * as ethers from 'ethers'
import { formatCurrency } from '../utils'

import aaveV3PoolAddressProviderAbi from '../abi/aaveV3PoolAddressProviderAbi.json'
import aaveV3PoolAbi from '../abi/aaveV3PoolAbi.json'
import aaveV3ProtocolDataProviderAbi from '../abi/aaveV3ProtocolDataProviderAbi.json'

export const AAVE_V3_POOL_ADDRESS_PROVIDER = '0x2f39d218133afab8f2b819b1066c7e434ad94e9e'
export const AAVE_V3_PROTOCOL_DATA_PROVIDER = '0x41393e5e337606dc3821075af65aee84d7688cbd'

const WETH_TOKEN_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'

let provider

const aaveReferralCode = Number(window.appVariables.aaveV3ReferralCode) || 0

currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!provider) throw new Error('Ethereum provider is not available')
}

async function getAddressProviderContract () {
  if (!provider) throw new Error('Ethereum provider is not available')

  const contract = await new ethers.Contract(AAVE_V3_POOL_ADDRESS_PROVIDER, aaveV3PoolAddressProviderAbi, provider.getSigner())
  return contract
}

async function getPoolContract () {
  if (!provider) throw new Error('Ethereum provider is not available')

  const addressProvider = await getAddressProviderContract()
  const poolAddress = await addressProvider.callStatic.getPool()
  const contract = await new ethers.Contract(poolAddress, aaveV3PoolAbi, provider.getSigner())
  return contract
}

async function getProtocolDataProviderContract () {
  const contract = await new ethers.Contract(AAVE_V3_PROTOCOL_DATA_PROVIDER, aaveV3ProtocolDataProviderAbi, provider.getSigner())
  return contract
}

export async function aaveV3GetUserReserveData (symbol) {
  await verifyPreconditions()

  const protocolDataProvider = await getProtocolDataProviderContract()

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  return await protocolDataProvider.callStatic.getUserReserveData(tokenAddress, get(connectedWalletAddress))
}

export async function aaveV3IsCollateralEnabled (symbol) {
  const { usageAsCollateralEnabled } = await aaveV3GetUserReserveData(symbol)

  return Number(usageAsCollateralEnabled) === 1
}

export async function aaveV3ActionEnableDisable (symbol, enabled) {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  steps.push({
    id: enabled ? 'enable' : 'disable',
    description: 'Update collateral usage state',
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (await aaveV3IsCollateralEnabled(symbol) === enabled) {
        return null
      } else {
        const hash = await waitForTxHashOnly(contract.setUserUseReserveAsCollateral(tokenAddress, enabled), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: enabled ? 'enable' : 'disable',
            symbol
          },
          icon: 'power-off',
          description: `${enabled ? 'Enable' : 'Disable'} ${symbol} for use in Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    }
  })

  return steps
}

export async function aaveV3ActionDeposit (symbol, amount, enable) {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  if (amount === ALL) {
    if (symbol === 'ETH') throw new Error('ALL is not supported for ETH')

    amount = await getErc20Balance(symbol)
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (symbol === 'ETH') {
    throw new Error('TODO: ETH is not implemented yet')
    // const approvalStep = await getWethBulkerAllowStep(contract)
    // if (approvalStep) steps.push(approvalStep)
  }

  if (Number(amount) > 0) {
    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(getApproveStep(symbol, contract.address, 'aaveV3'))
    }

    steps.push({
      id: 'deposit',
      description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const hash = await waitForTxHashOnly(contract.deposit(tokenAddress, amountInSmallestUnit, get(connectedWalletAddress), aaveReferralCode), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: 'deposit',
            symbol,
            amount: await getAmountForDisplay(symbol, amount)
          },
          icon: 'sign-in-alt',
          description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })
  }

  if (enable != null) {
    steps.push(...await aaveV3ActionEnableDisable(symbol, enable))
  }

  return steps
}

export async function aaveV3ActionWithdraw (symbol, amount) {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  let actualAmount = amount
  if (amount === ALL) {
    actualAmount = MAX_UINT256
  }

  if (symbol === 'ETH') {
    throw new Error('TODO: ETH is not implemented yet')
    // const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    // if (approvalStep) steps.push(approvalStep)
  }

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)

  steps.push({
    id: 'withdraw',
    description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = await waitForTxHashOnly(contract.withdraw(tokenAddress, amountInSmallestUnit, get(connectedWalletAddress)), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV3',
          type: 'withdraw',
          symbol,
          ...amount === ALL ? { all: true } : { amount: Number(amount) }
        },
        icon: 'sign-out-alt',
        description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from Aave V3`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV3ActionBorrow (symbol, amount, interestRateMode = 'Variable') {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (amount === ALL) throw new Error('ALL is not supported for borrow')

  if (symbol === 'ETH') {
    throw new Error('TODO: ETH is not implemented yet')
    // const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    // if (approvalStep) steps.push(approvalStep)
  }

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)

  steps.push({
    id: 'borrow',
    description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = await waitForTxHashOnly(contract.borrow(tokenAddress, amountInSmallestUnit, interestRateMode === 'Stable' ? 1 : 2, aaveReferralCode, get(connectedWalletAddress)), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV3',
          type: 'borrow',
          symbol,
          amount: Number(amount)
        },
        icon: 'sign-out-alt',
        description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from Aave V3`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV3ActionRepay (symbol, amount, interestRateMode = null) {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  let actualAmount = amount
  if (amount === ALL) {
    actualAmount = MAX_UINT256
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (symbol === 'ETH') {
    throw new Error('TODO: ETH is not implemented yet')
    // const approvalStep = await getWethBulkerAllowStep(baseSymbol, contract)
    // if (approvalStep) steps.push(approvalStep)
  }

  if (!interestRateMode) {
    const amountInSmallestUnit = amount === ALL ? null : await getAmountInSmallestUnit(symbol, Number(amount))
    const amountBN = amountInSmallestUnit && ethers.BigNumber.from(amountInSmallestUnit)

    const userReserveData = await aaveV3GetUserReserveData(symbol)

    const stableDebtBalance = userReserveData.currentStableDebt
    const variableDebtBalance = userReserveData.currentVariableDebt

    if (!Number(stableDebtBalance) && !Number(variableDebtBalance)) return []

    if (stableDebtBalance.gt(0) && variableDebtBalance.gt(0)) {
      if (amount === ALL) {
        return [...await aaveV3ActionRepay(symbol, ALL, 'Stable'), ...await aaveV3ActionRepay(symbol, ALL, 'Variable')]
      } else {
        const priority = await dialogs.open(RepayPriorityPrompt, {
          symbol,
          stableAmount: await getAmountForDisplay(symbol, stableDebtBalance),
          variableAmount: await getAmountForDisplay(symbol, variableDebtBalance)
        })

        if (!priority) return

        const order = [{ type: 'Variable', balance: variableDebtBalance }, { type: 'Stable', balance: stableDebtBalance }]
        if (priority === 'Variable') order.reverse()

        const steps = []
        let toBePaid = ethers.BigNumber.from(amountBN)
        for (const repayObject of order) {
          if (toBePaid.gte(repayObject.balance)) {
            steps.push(...await aaveV3ActionRepay(symbol, ALL, repayObject.type))
            toBePaid = toBePaid.sub(repayObject.balance)
          } else {
            steps.push(...await aaveV3ActionRepay(symbol, toBePaid, repayObject.type))
            toBePaid = ethers.BigNumber.from(0)
            break
          }
        }

        if (!steps.length) throw new Error('No repay steps')
        return steps
      }
    } else if (stableDebtBalance.gt(0)) {
      return await aaveV3ActionRepay(symbol, amount, 'Stable')
    } else if (variableDebtBalance.gt(0)) {
      return await aaveV3ActionRepay(symbol, amount, 'Variable')
    } else {
      throw new Error('No debt to repay')
    }
  } else {
    // We are comparing with half the maxiumum allowance here in case there are contracts that do not handle -1 separately and have already deducted a bit.
    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)
    const amountInSmallestUnitForApprovalCheck = amount === ALL ? HALF_MAX_UINT256 : await getAmountInSmallestUnit(symbol, actualAmount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnitForApprovalCheck === 'number' ? amountInSmallestUnitForApprovalCheck.toFixed(0) : amountInSmallestUnitForApprovalCheck))) {
      steps.push(getApproveStep(symbol, contract.address, 'aaveV3'))
    }

    steps.push({
      id: 'repay',
      description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const hash = await waitForTxHashOnly(contract.repay(tokenAddress, amountInSmallestUnit, interestRateMode === 'Stable' ? 1 : 2, get(connectedWalletAddress)), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: 'repay',
            symbol,
            ...amount === ALL ? { all: true } : { amount: Number(amount) }
          },
          icon: 'sign-in-alt',
          description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })

    return steps
  }
}
