<script>
  import { createEventDispatcher } from 'svelte'
  import ModalDialog from './ModalDialog.svelte'

  const dispatch = createEventDispatcher()

  export let value
  export let items
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 150%;
  }

  hr {
    margin: 0;
  }

  hr:last-child {
    display: none;
  }

  a {
    color: $grey;
    border-radius: 0.5rem;
    text-align: center;
    text-transform: uppercase;

    &.active {
      font-weight: bold;
    }

    &:hover {
      opacity: 0.75;
    }
  }
</style>

<ModalDialog on:close fieldBg>
  <main>
    {#each items as item (item.id)}
      <a href={undefined} on:click={() => dispatch('close', item.id)} class:active={value === item.id}>
        <slot {item}>{item.name}</slot>
      </a>
      <hr />
    {/each}
  </main>
</ModalDialog>
