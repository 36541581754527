const services = Object.fromEntries([
  { id: 'compoundV2', name: 'Compound V2', icon: '/images/services/compound.svg', badge: 'V2' },
  { id: 'compoundV3_USDC', name: 'Compound V3 USDC', icon: '/images/services/compound.svg', badge: 'V3' },
  { id: 'aaveV2', name: 'Aave V2', icon: '/images/services/aave.svg', badge: 'V2' },
  { id: 'aaveV3', name: 'Aave V3', icon: '/images/services/aave.svg', badge: 'V3', isBeta: true, actionsNotYetSupported: true }
].map(service => [service.id, service]))

export const DEFAULT_SERVICE_ICON = '/images/services/default.svg'

export default services
