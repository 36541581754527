export { getCollateralOverallLiquidationThreshold, calculateFullState } from '../../shared/lib/fullStateCalculator'

export function sortAssets (assets) {
  // Merge duplicate assets which would otherwise crash the template
  const assetsMap = {}
  for (const asset of assets) {
    if (!assetsMap[asset.symbol]) {
      assetsMap[asset.symbol] = { ...asset }
    } else {
      console.warn(`Duplicate asset ${asset.symbol} - merging`, assets)
      assetsMap[asset.symbol].units += asset.units
      assetsMap[asset.symbol].enabled = assetsMap[asset.symbol].enabled || asset.enabled
    }
  }

  return Object.values(assetsMap)
    .sort((a, b) => b.ethValue - a.ethValue || a.symbol.localeCompare(b.symbol))
    .filter(asset => asset.coin)
}

export function getEmptyCoreData (service) {
  return { service, assets: { collateral: [], borrow: [] }, dirty: false, notificationsEnabled: false }
}

export function getInitialCoreData (service) {
  return getEmptyCoreData(service)
}

export function isEmpty (coreData) {
  return !coreData?.assets.collateral.length && !coreData?.assets.borrow.length
}
