'use strict'

// https://spaceavocado.github.io/svelte-router/
import createRouter from '@spaceavocado/svelte-router'
import { get } from 'svelte/store'
import { Toast } from 'svelma-fixed'

// View components
import View404 from './views/View404.svelte'
import ViewCalculator from './views/ViewCalculator.svelte'
import ViewBorrowLimits from './views/ViewBorrowLimits.svelte'
import ViewUnsubscribe from './views/ViewUnsubscribe.svelte'
import ViewLoanComparison from './views/ViewLoanComparison.svelte'
import ViewApiDocs from './views/ViewApiDocs.svelte'
import ViewThorchain from './views/ViewThorchain.svelte'

import ViewTxTest from './views/ViewTxTest.svelte'
import ViewComponentTest from './views/ViewComponentTest.svelte'
import ViewTxManagerTest from './views/ViewTxManagerTest.svelte'
import ViewDocBrown from './views/ViewDocBrown.svelte'

export { default as RouterView } from '@spaceavocado/svelte-router/component/view'
export { default as RouterLink } from '@spaceavocado/svelte-router/component/link'

export const router = createRouter({
  activeClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'HOME',
      redirect: { name: 'calculator' }
    },
    {
      path: '/calculator/:address?',
      name: 'calculator',
      component: ViewCalculator,
      props: route => ({ addressFromUrl: route.params.address, isManage: false })
    },
    {
      path: '/manage',
      name: 'manage',
      component: ViewCalculator,
      props: route => ({ isManage: true })
    },
    {
      path: '/borrow-limits',
      name: 'borrowLimits',
      component: ViewBorrowLimits
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: ViewUnsubscribe,
      props: route => ({ token: route.query.token })
    },
    {
      path: '/compare',
      name: 'compare',
      component: ViewLoanComparison
    },
    {
      path: '/thorchain',
      name: 'thorchain',
      component: ViewThorchain
    },
    {
      path: '/api-docs',
      name: 'apiDocs',
      component: ViewApiDocs
    },
    {
      path: '/tx-test',
      name: 'txTest',
      component: ViewTxTest
    },
    {
      path: '/component-test',
      name: 'componentTest',
      component: ViewComponentTest
    },
    {
      path: '/tx-manager-test',
      name: 'txManagerTest',
      component: ViewTxManagerTest
    },
    {
      path: '/doc-brown',
      name: 'docBrown',
      component: ViewDocBrown
    },
    {
      path: '*',
      component: View404
    }
  ]
})

get(router).navigationGuard((from, to, next) => {
  console.log('Navigation attempt:', from, to)
  next()
})

get(router).onError(error => {
  Toast.create({ message: error, type: 'is-danger' })
  console.error('Router error', error)
})

export let shouldFixRouter = true

// This can be used to update props and route without reinstantiating component
export function softNavigate (route, operation = 'push') {
  try {
    shouldFixRouter = false
    if (route) get(router)[operation](route)
  } finally {
    setTimeout(() => {
      shouldFixRouter = true
    }, 0)
  }
}
