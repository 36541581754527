import { persistentWritable } from './persistentStore'

export const devOptions = persistentWritable('wehodlDevOptions', { featureFlags: {}, debugOptions: {} })
export let currentDevOptions
devOptions.subscribe($currentDevOptions => {
  currentDevOptions = $currentDevOptions
})

export function save () {
  devOptions.set(currentDevOptions)
}

export default devOptions
