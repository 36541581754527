<script>
  export let value = null
  export let options = []
  export let disabled = false

  import { createEventDispatcher } from 'svelte'
  import { generateRandomString } from '../lib/utils'

  const dispatch = createEventDispatcher()

  function handleRadioButtonClick (event, newValue) {
    value = newValue
    dispatch('change', event.detail)
  }

  const name = generateRandomString()
</script>

<style lang="scss">
  .radio {
    line-height: 2rem;

    > * {
      vertical-align: middle;
    }
  }

  .control {
    line-height: 2em;
    padding: 0.3333em 0;
  }
</style>

<div class="control {$$props.class ?? ''}">
  {#each options as [optValue, optLabel] (optValue)}
    <label class="radio" class:has-cursor-not-allowed={disabled}>
      <input type="radio" {name} on:click={event => handleRadioButtonClick(event, optValue)} checked={value === optValue} disabled={disabled}>
      <span>{optLabel}</span>
    </label>
  {/each}
</div>
